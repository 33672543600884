import {Component, OnDestroy, OnInit} from '@angular/core';
import {HotelDetailModel, HotelModel, OfferModel, ReservationModel, SearchModel} from '../../models';
import {HotelService} from '../../services/hotel.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralUtil} from '../../core/util/general.util';
import {Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'app-book-hotel',
  templateUrl: './book-hotel.component.html',
  styleUrls: ['./book-hotel.component.css']
})
export class BookHotelComponent implements OnInit, OnDestroy {

  search: SearchModel = null;
  hotel: HotelModel = null;
  offer: OfferModel;
  subscriptionObject: Subscription;
  subscriptionPriceBK: Subscription;
  reservation: ReservationModel;

  priceBDShow: boolean = false;


  subscriptionQuoteData: Subscription;

  quoteID: string;
  offerID: string;

  constructor(
    private _hotelService: HotelService,
    private _router: Router,
    private _routeHandler: ActivatedRoute,
    private _spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.subscriptionPriceBK = new Subscription();
    this.quoteID = this._routeHandler.snapshot.paramMap.get('quoteID');
    this.offerID = this._routeHandler.snapshot.paramMap.get('offerID');

    if (this.quoteID !== null && this.offerID !== null) {
      this.subscriptionQuoteData = new Subscription();
      this.reservation = new ReservationModel();
      this.reservation.actual_time = new Date().getTime();
      this.getQuoteData();
    } else {
      this.search = this._hotelService.getReservation().search;
      this.hotel = this._hotelService.getReservation().hotel;
      this.offer = this._hotelService.getReservation().offer;
      this.subscriptionObject = new Subscription();

      if (!GeneralUtil.isListHotelsAlive()) {
        this.searchExpireMessage();
        return;
      }

      if (!this.search || !this.offer) {
        this._router.navigate(['/hotel_search']).then();
      }


      if (!this.offer.price_break_down && this.hotel.provider.id === 1) {
        this.getPriceBreakDown();
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }


  getPriceBreakDown() {
    this._spinner.show().then();
    this.subscriptionPriceBK.add(this._hotelService.getPriceBreakDown(this.offer.hotel_search_code).subscribe(
      (data) => {
        this.offer.price_break_down = data.data;
        this._spinner.hide().then();
      }
    ));
  }

  getQuoteData() {
    this._spinner.show().then();
    this.subscriptionQuoteData.add(this._hotelService.getOffer(this.quoteID, this.offerID).subscribe(
      (data) => {
        this._spinner.hide().then();

        const offerData: OfferModel = {
          room_basis: data.data.quotation_offer.room_basis,
          room_type: data.data.quotation_offer.offer_name,
          room_type_array: [data.data.quotation_offer.room_type_array],
          hotel_search_code: data.data.quotation_offer.hotel_search_code,
          room_basis_description: data.data.quotation_offer.room_basis_description,
          availability: data.data.quotation_offer.availability,
          base_price: data.data.quotation_offer.price_provider,
          total_price: data.data.quotation_offer.price_for_client,
          currency: data.data.quotation_offer.currency.abbreviation,    // Missing Data
          cxl_deadline: data.data.quotation.cxl_deadline, // Missing Data
          preferred: data.data.quotation_offer.preferred,
          // tslint:disable-next-line:max-line-length
          remark: data.data.quotation_offer.remark,
          // tslint:disable-next-line:max-line-length
          special_offer: data.data.quotation_offer.special_offer,
          meal_plan: data.data.quotation_offer.meal_plan,
          market_id: data.data.quotation_offer.market_id,
          contract_id: data.data.quotation_offer.contract_id,
          rate_key: data.data.quotation_offer.rate_key,
          interface_info: data.data.quotation.interface_info,
          nigth_price: null,
          price_break_down: null,
        };

        const hotelDetail: HotelDetailModel = {
          id: data.data.hotel_data.id,
          hotel_code: data.data.hotel_data.hotel_code,
          hotel_name: data.data.hotel_data.hotel_name,
          stars: data.data.hotel_data.stars,
          thumbnail: data.data.hotel_data.thumbnail,
          address: data.data.hotel_data.address,
          city_code: data.data.hotel_data.city.id,
          phone: data.data.hotel_data.phone,
          fax: data.data.hotel_data.fax,
          description: data.data.hotel_data.description,
          descriptionArray: data.data.hotel_data.descriptionArray,
          hotel_facilities: data.data.hotel_data.hotel_facilities,
          room_facilities: data.data.hotel_data.room_facilities,
          room_count: data.data.hotel_data.room_count,
          pictures: data.data.hotel_data.pictures,
          trip_advisor: data.data.hotel_data.trip_advisor,
          latitude: data.data.hotel_data.latitude,
          longitude: data.data.hotel_data.longitude
        };

        const hotelData = {
          id: data.data.hotel_data.id,
          hotel_code: data.data.hotel_data.hotel_code,
          hotel_name: data.data.hotel_data.hotel_name,
          country_id: data.data.hotel_data.country.id,
          provider_id: data.data.quotation.provider_id,
          provider: {name: '', id: data.data.quotation.provider_id},
          stars: data.data.hotel_data.stars,
          location: data.data.hotel_data.location, // Missing Data
          location_code: 0, // Remove from Project Data
          thumbnail: data.data.hotel_data.thumbnail,
          low_cost: data.data.quotation_offer.price_for_client,
          low_cost_currency: data.data.quotation_offer.currency.abbreviation,  // Missing Data
          offers: [offerData],
          interface_info: data.data.quotation.interface_info,
          details_data: hotelDetail
        };

        const check_in: Date = new Date(data.data.quotation.check_in);
        const check_out: Date = new Date(data.data.quotation.check_out);

        const checkInStr = check_in.getFullYear() + '-' + (check_in.getMonth() + 1) + '-' + check_in.getDate();
        const checkInStrv2 = check_in.getDate() + '/' + (check_in.getMonth() + 1) + '/' + check_in.getFullYear();
        const checkOutStr = check_out.getFullYear() + '-' + (check_out.getMonth() + 1) + '-' + check_out.getDate();
        const checkOutStrv2 = check_out.getDate() + '/' + (check_out.getMonth() + 1) + '/' + check_out.getFullYear();

        const tempRooms = JSON.parse(data.data.quotation.rooms_persons);
        const newRooms = [];

        let roomIndex = 1;
        for (const room of tempRooms) {
          let age1, age2, age3, age4;
          if (room.ages[0]) {
            age1 = room.ages[0];
          } else {
            age1 = 0;
          }
          if (room.ages[1]) {
            age2 = room.ages[1];
          } else {
            age2 = 0;
          }
          if (room.ages[2]) {
            age3 = room.ages[2];
          } else {
            age3 = 0;
          }
          if (room.ages[3]) {
            age4 = room.ages[3];
          } else {
            age4 = 0;
          }
          newRooms.push({
            room: roomIndex,
            adult: room.adults,
            child: room.childrens,
            age1: age1,
            age2: age2,
            age3: age3,
            age4: age4,
          });
          roomIndex++;
        }

        const searchData = {
          destination_id: data.data.hotel_data.city.id,
          check_in: checkInStr,
          check_out: checkOutStr,
          rooms_number: data.data.quotation.rooms,
          rooms_persons: newRooms,
          myDateRange: {
            beginDate: {
              year: check_in.getFullYear(),
              month: (check_in.getMonth() + 1),
              day: check_in.getDate()
            },
            beginJsDate: check_in.toUTCString(),
            endDate: {
              year: check_out.getFullYear(),
              month: (check_out.getMonth() + 1),
              day: check_in.getDate()
            },
            endJsDate: check_out.toUTCString(),
            formatted: checkInStrv2 + '-' + checkOutStrv2,
            beginEpoc: check_in.getUTCMilliseconds(),
            endEpoc: check_out.getUTCMilliseconds()
          },
          destination: data.data.hotel_data.city_code,
          adults: data.data.quotation.adult,
          childrens: data.data.quotation.children,
        };

        this.search = _.cloneDeep(searchData);
        this.hotel = _.cloneDeep(hotelData);

        this.reservation.search = this.search;
        this.reservation.offer = this.hotel.offers[0];
        this.reservation.hotel = this.hotel;
        this.reservation.list_hotels = null;
        this.reservation.actual_time = new Date().getTime();
        this.reservation.quoteReservation = true;
        this.reservation.guests = [];

        this._hotelService.setReservation(this.reservation);


        this.search = this._hotelService.getReservation().search;
        this.hotel = this._hotelService.getReservation().hotel;
        this.offer = this._hotelService.getReservation().offer;
        this.subscriptionObject = new Subscription();

        if (!this.search || !this.offer) {
          this._router.navigate(['/hotel_search']).then();
        }

        if (!this.offer.price_break_down && this.hotel.provider.id === 1) {
          this.getPriceBreakDown();
        }

      }, (errMsg) => {
        this._spinner.hide().then();
        this.quoteError(errMsg.error.message);
      }, () => {
        this._spinner.hide().then();
      }
    ));
  }

  quoteError(errMsg) {
    Swal.fire({
      type: 'error',
      title: 'Error',
      text: errMsg,
      confirmButtonText: 'Back to Home',
    }).then((result) => {
      if (result.value) {
        this._router.navigate(['/home']).then();
      }
    });
  }

  getNights() {
    return GeneralUtil.getNights();
  }

  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        const reserve = this._hotelService.getReservation();
        reserve.automatic_refresh = true;
        this._hotelService.setReservation(reserve);
        this._router.navigate(['/hotel_search']).then();
      }
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

}
