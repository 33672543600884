import {Component, OnInit} from '@angular/core';

declare function initPageGallery(ID: any);

declare function hgClear(): any;

@Component({
  selector: 'app-nautica-gallery',
  templateUrl: './nautica-gallery.component.html',
  styleUrls: ['./nautica-gallery.component.css']
})
export class NauticaGalleryComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    var sto = setTimeout(function () {
      initPageGallery('nautica');
    }, 1500);
  }

  onClickHotel() {
  }

}
