import {FormArray, FormControl, FormGroup} from '@angular/forms';
import Swal from 'sweetalert2';
import {StoreUtil} from './store.util';

export class GeneralUtil {

  static roundNumber(number: number, placesAfterCom: number): number {
    let factor = 1;
    for (let i = 0; i < placesAfterCom; i++) {
      factor *= 10;
    }
    return Math.round(number * factor) / factor;
  }

  static successMessage(message, tim: number = 2500) {
    Swal.fire({
      type: 'success',
      title: message,
      showConfirmButton: false,
      timer: tim
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }


  static errorMessage(message) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      html: message,
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  static infoMessage(message, tim: number = 2500) {
    Swal.fire({
      type: 'info',
      title: 'Information...',
      showConfirmButton: false,
      timer: tim,
      text: message,
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  static infoMessage2(message, tim: number = 2500) {
    Swal.fire({
      type: 'info',
      showConfirmButton: false,
      timer: tim,
      html: message,
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

  static isTokenAlive(token: any): boolean {
    const time = token.expires_in * 1000;
    const currentMillis = new Date().getTime();
    if (time > (currentMillis - token.millis)) {
      return true;
    } else {
      return false;
    }
  }

  static isListHotelsAlive(): boolean {
    const reservation = StoreUtil.objectFromStore('reservation');
    if (reservation.list_hotels || reservation.offer) {
      const time = reservation.actual_time;
      const flag = 19 * 60 * 1000;
      const currentMillis = new Date().getTime();
      if ((currentMillis - time) < flag) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  static calcOrdinals(i) {
    let numberOrdinal = i + 'th';
    switch (i) {
      case 1:
        numberOrdinal = '1st';
        break;
      case 2:
        numberOrdinal = '2nd';
        break;
      case 3:
        numberOrdinal = '3rd';
        break;
    }
    return numberOrdinal;
  }


  static getNights() {
    const search = StoreUtil.objectFromStore('reservation').search;
    const date_1: any = new Date(search.check_in);
    const date_2: any = new Date(search.check_out);

    const day_as_milliseconds = 86400000;
    const diff_in_millisenconds = date_2 - date_1;
    const diff_in_days = diff_in_millisenconds / day_as_milliseconds;

    return diff_in_days;
  }


  static validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      } else if (control instanceof FormArray) {        //{5}
        for (let j = 0; j < control.controls.length; j++) {
          this.validateAllFormFields(control.controls[j] as FormGroup);            //{6}
        }

      }
    });
  }


  static esEntero(numero) {
    if (isNaN(numero)) {
      return false;
    } else {
      if (numero % 1 == 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  static validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(String(email).toLowerCase());
  }

}
