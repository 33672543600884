import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '../models';
import {LoginService} from '../services/login.service';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {GeneralUtil} from '../core/util/general.util';

declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-p-main-menu',
  templateUrl: './p-main-menu.component.html',
  styleUrls: ['./p-main-menu.component.css']
})
export class PMainMenuComponent implements OnInit {

  subscriptionLogin: Subscription;

  n_messages = 0;
  userLogged: UserModel;

  constructor(private _router: Router, private _loginService: LoginService) {
  }

  ngOnInit() {
    this.subscriptionLogin = new Subscription();
    this.userLogged = this._loginService.getUserInfo();
    this.listenLogIn();
    this.listenLogout();
  }

  listenLogIn() {
    if (!this.userLogged) {
      this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
        (data) => {
          switch (data) {
            case null:
            case undefined:
              this.userLogged = null;
              break;
            default:
              this.userLogged = data;
              break;
          }
        })
      );
    }
  }

  listenLogout() {
    this._loginService._logged.subscribe(
      (data) => {
        if (!data) {
          this.userLogged = null;
        } else {
          this.userLogged = data;
        }
      }
    );
  }

  onLogout() {
    // const currentUrl = this._router.url;
    Swal.fire({
      title: 'Warning!',
      text: 'Are you sure you wish to logout?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!'
    }).then((result) => {
      if (result.value) {
        this._loginService.doLogout().subscribe(
          async () => {
            this._loginService.fireLoginChange(null);
            GeneralUtil.successMessage('You have been logout!');
            localStorage.clear();
            this._router.navigate(['/home']).then();
          }, (err) => {
            if (err.status === 401) {
              this._loginService.fireLoginChange(null);
              GeneralUtil.successMessage('You have been logout!');
              localStorage.clear();
              this._router.navigate(['/home']).then();
              return;
            }
            if (err.error.message) {
              GeneralUtil.errorMessage(err.error.message);
              return;
            }
            if (err.message) {
              GeneralUtil.errorMessage(err.message);
              return;
            }
          }
        );
      }
    });
  }

  goLoginOrRegister() {
    Swal.fire({
      // title: 'Login or Register',
      confirmButtonText: 'CANCEL',
      html: '<h3 class="title text-c4 tac text-uc mb-2">Please</h3>' +
        '<button id="login" class="btn btn-nor btn-2nd full-w iblock">' +
        'LOG IN</button>' +
        '<h3 class="title text-c4 tac text-uc py-2">or</h3>' +
        '<div class="rounded bg-c4 p-2">' +
        '<button id="register" class="btn btn-nor btn-3rd full-w iblock">REGISTER</button>' +
        '<p class="font-round mt-2 text-w">Most high-end experience<br>at the most affordable price.</p>' +
        '</div>',
      customClass: {
        confirmButton: 'btn btn-sm btn-5th full-w'
      },
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const login = $('#login');
        const register = $('#register');

        login.addEventListener('click', () => {
          this._router.navigate(['/login'], {queryParams: {redirect: 'my.bookings'}}).then();
          Swal.close();
        });

        register.addEventListener('click', () => {
          this._router.navigate(['/register'], {queryParams: {redirect: 'my.bookings'}}).then();
          Swal.close();
        });

      }
    });
  }

  onMenuToggle(event) {
    event.stopPropagation();
    document.getElementById('main-menu').classList.toggle('in');
  }

  onGoRoute(event, route) {
    this.onMenuToggle(event);
    setTimeout(() => {
      this._router.navigate([route]);
    }, 400);
  }

  onNoPage(event, wID) {
    this.onMenuToggle(event);
    //
    openWindow(event, wID);
  }

}
