import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {GeneralUtil} from '../core/util/general.util';
import {UserService} from '../services/user.service';

declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  subscriptActivateAccount: Subscription;
  activationCode: string;

  constructor(
    private _routeHandler: ActivatedRoute,
    private _userService: UserService) {
  }

  ngOnInit() {
    this._routeHandler.queryParams.subscribe(params => {
      if (params['activation_code']) {
        this.subscriptActivateAccount = new Subscription();
        this.activationCode = params['activation_code'];
        this.activateAccount();
      }
    });
  }

  activateAccount() {
    this.subscriptActivateAccount.add(this._userService.confirmAccount(this.activationCode).subscribe(
      (data) => {
        GeneralUtil.successMessage(data.message);
      }, (error) => {
        if (error.error.message) {
          GeneralUtil.errorMessage(error.error.message);
        }
        if (error.message) {
          GeneralUtil.errorMessage(error.message);
        }
      }
    ));
  }

  onNoPage(event, wID) {
    //
    openWindow(event, wID);
  }

}
