import {Component, OnInit} from '@angular/core';

declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'w-app-accomodation-detail',
  templateUrl: './w-accomodation-detail.component.html',
  styleUrls: ['./w-accomodation-detail.component.css']
})
export class AccomodationDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  closeW(event, wID) {
    closeWindow(event, wID);
  }

  onGoDetails(event, wID) {
    closeWindow(event, wID);
    //
  }
}
