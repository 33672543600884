import {Component, OnInit} from '@angular/core';
import {HotelDetailModel} from '../models';
import {Subscription} from 'rxjs';
import {HotelService} from '../services/hotel.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {RequestParams} from '../core/api/api.model';
import Swal from 'sweetalert2';

declare function closeGallery(event: any);

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})

export class GalleryComponent implements OnInit {

  hotelDetail: HotelDetailModel;
  subscriptionObject: Subscription;

  id = 'idxxx';
  imgsTotal = 5;
  currImg = 0;

  constructor(private _hotelService: HotelService, private _spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();
    this.listenOpenWindow();
  }

  listenOpenWindow() {
    this.subscriptionObject.add(this._hotelService._fireOpenGalleryWindow.subscribe(
      (data) => {
        this.hotelDetail = data.hotel.details_data;
        this.currImg = 0;
        if (!data.hotel.details_data || data.hotel.details_data.pictures.length == 0) {
          this.getHotelDetails(data.hotel_search_code);
        } else {
          this.imgsTotal = this.hotelDetail.pictures.length;
        }
      }
    ));

  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  getHotelDetails(hotel_search_code: string) {
    var params: RequestParams = {
      params: {
        id: 1
      },
      search: {
        id_search_code: hotel_search_code
      }
    };
    this._spinner.show();
    this._hotelService.getHotelDetails(params).subscribe(
      (data) => {
        this._spinner.hide();
        this.hotelDetail = data.data;
        this.imgsTotal = this.hotelDetail.pictures.length;
      }, (error) => {
        this._spinner.hide();
        if (error.name && error.name == 'TimeoutError') {
          Swal.fire({
            title: 'A time out error ocurred',
            text: 'The provider is temporarily out of service. Repeat your request.',
            type: 'error',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: ' Repeat request'
          }).then((result) => {
            if (result.value) {
              this.getHotelDetails(hotel_search_code);
            } else {
              this.onCloseGallery(event);
            }
          });
        }
      }
    );
  }

  onPrevImg(event) {
    /*  event.stopPropagation();
     var gc = document.getElementById('gallery-container') as HTMLDivElement;
     var activeImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    //
    this.currImg--;
    this.currImg = this.currImg < 0 ? this.imgsTotal - 1 : this.currImg;
    /*  var nextImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    /* activeImg.classList.remove('active');
    setTimeout(() => {
      nextImg.classList.add('active');
    }, 250); */
  }

  onNextImg(event) {

    /* event.stopPropagation();
    var gc = document.getElementById('gallery-container') as HTMLDivElement;
    var activeImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    //
    this.currImg++;
    this.currImg = this.currImg > this.imgsTotal - 1 ? 0 : this.currImg;
    /* var nextImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    /* activeImg.classList.remove('active');
    setTimeout(() => {
      nextImg.classList.add('active');
    }, 250); */
  }


  onErrorImage(event, i) {
    this.imgsTotal -= 1;
    this.hotelDetail.pictures = this.hotelDetail.pictures.filter((element, index) => {
      return i != index;
    });
  }

  onCloseGallery(event) {
    event.stopPropagation();
    this._spinner.hide();
    closeGallery(event);
  }
}
