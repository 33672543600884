import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralUtil} from '../core/util/general.util';
import {CountryModel} from '../models/country.model';
import {Subscription} from 'rxjs';
import {CommonService} from '../services/common.service';
import {INTEGER_VALIDATOR} from '../core/util/form-validators.util';
import {UserModel} from '../models';
import {UserService} from '../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

  registerForm: FormGroup;
  countries: CountryModel[];
  subscriptionObject: Subscription;
  redirectTo: string;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _commonService: CommonService, private _userService: UserService, private _spinner: NgxSpinnerService) {
  }


  ngOnInit() {
    this.redirectTo = this._route.snapshot.queryParams.redirect;
    if (!this.redirectTo) {
      this.redirectTo = 'home';
    }
    this.subscriptionObject = new Subscription();
    this.llenarFormulario();
    this.getPhoneCodes();
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  getPhoneCodes() {
    this.subscriptionObject.add(this._commonService.getPhoneCodes().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }

  onChange() {
    var valSelect = this.registerForm.get('phone_number').value.countrysel;
    this.registerForm.patchValue({
      phone_number: {country: valSelect},
    });
  }

  onSubmit() {
    if (this.registerForm.valid) {

      if (this.registerForm.get('password').value !== this.registerForm.get('password_confirmation').value) {
        GeneralUtil.errorMessage('Passwords are different!');
        return;
      }

      if (!GeneralUtil.validateEmail(this.registerForm.get('email').value)) {
        GeneralUtil.errorMessage('Invalid Email Address!');
        return;
      }
      var ticket = new UserModel();
      const model = Object.assign({}, ticket, this.registerForm.value);
      model.phone_number = model.phone_number.country + '-' + model.phone_number.area + '-' + model.phone_number.number;
      model.redirect = this.redirectTo;
      this._spinner.show();
      this._userService.register(model).subscribe(
        (data) => {
          this._spinner.hide();
          this._router.navigate(['/' + this.redirectTo]);
          GeneralUtil.successMessage(data.message, 4000);
        });
    } else {
      GeneralUtil.validateAllFormFields(this.registerForm);
    }
  }


  llenarFormulario() {
    this.registerForm = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'last_name': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required),
      'password_confirmation': new FormControl(null, Validators.required),
      'phone_number': new FormGroup({
        'countrysel': new FormControl('', [Validators.required]),
        'country': new FormControl(null, [Validators.required]),
        'area': new FormControl(null, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(3), Validators.maxLength(3)]),
        'number': new FormControl(null, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(5), Validators.maxLength(7)]),
      }),
    });
  }

}
