import {Component, OnInit} from '@angular/core';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {Router} from '@angular/router';
import {NauticalService} from '../../services/nautical.service';

declare function pageGalleryLeft(ID: any, Num: number);

declare function pageGalleryRight(ID: any, Num: number);

declare function resetCurrImg(): any;

@Component({
  selector: 'app-nautical',
  templateUrl: './nautical.component.html',
  styleUrls: ['./nautical.component.css']
})
export class NauticalComponent implements OnInit {

  packagesList: NauticalModel[];

  constructor(
    private _router: Router,
    private _nautService: NauticalService) {
  }

  ngOnInit() {
    this.getPackages().then(() => resetCurrImg());
  }

  async getPackages() {
    this.packagesList = [];
    this.packagesList.push(
      {
        totalPeople: 20,
        location: 'Isla Mujeres VIP',
        package_name: 'Luxury 55\' Yacht - 6 Hours',
        duration: 6,
        images: [
          '../../assets/images/yacht/yachtSample/0.jpg',
          '../../assets/images/yacht/prestige/exterior 1.jpg',
          '../../assets/images/yacht/yachtSample/1.jpg',
          '../../assets/images/yacht/prestige/exterior 2.jpg',
          '../../assets/images/yacht/yachtSample/2.jpg'],
        features: [
          'Soft drinks, beers, and water bottles',
          'Snack varieties',
          'Towels, tanner and sunscreens',
          'Inflatable sea toys',
          'Docking fee included',
          'Experienced crew',
          'VIP Transportation from your hotel in Cancun and Playa del Carmen'
        ],
        price: 1800,
        package_information: [
          'Transportation included',
          'Up to 20 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: true
      }
    );
    this.packagesList.push(
      {
        totalPeople: 20,
        location: 'Isla Mujeres VIP',
        package_name: 'Luxury 55\' Yacht - 8 Hours',
        duration: 8,
        images: [
          '../../assets/images/yacht/yachtSample/1.jpg',
          '../../assets/images/yacht/prestige/exterior 4.jpg',
          '../../assets/images/yacht/prestige/exterior 7.jpg',
          '../../assets/images/yacht/prestige/interior 2.jpg',
          '../../assets/images/yacht/prestige/interior 1.jpg',],
        features: [
          'Soft drinks, beers, and water bottles',
          'Snack varieties',
          'Towels, tanner and sunscreens',
          'Inflatable sea toys',
          'Docking fee included',
          'Experienced crew',
          'VIP Transportation from your hotel in Cancun and Playa del Carmen'
        ],
        price: 2200,
        package_information: [
          'Transportation included',
          'Up to 20 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: true
      }
    );
    this.packagesList.push(
      {
        totalPeople: 4,
        location: 'Isla Mujeres VIP',
        package_name: 'Sunset marriage proposal',
        duration: 3,
        images: [
          '../../assets/images/yacht/yachtSample/3.jpg',
          '../../assets/images/yacht/prestige/exterior 3.jpg',
          '../../assets/images/yacht/prestige/exterior 9.jpg',
          '../../assets/images/yacht/prestige/interior 4.jpg',
          '../../assets/images/yacht/prestige/interior 3.jpg',],
        features: [
          'Champagne bottle',
          'Docking fee included ',
          'Experienced crew'
        ],
        price: 800,
        package_information: [
          'Up to 4 people',
          'Taxes & fees included'
        ],
        currency: 'USD',
        vipTransportation: false
      }
    );
  }

  onLeft(event, Num) {
    pageGalleryLeft('nautica-scroll-container-', Num);
    //
  }

  onRight(event, Num) {
    pageGalleryRight('nautica-scroll-container-', Num);
    //
  }

  onGoRoute(data) {
    return;
  }

  onMoreInfo(event, nautPack) {
    event.stopPropagation();
    //
    const reservation = new NauticalReservationModel();
    reservation.nautical_package = nautPack;
    this._nautService.setReservation(reservation);
    this._router.navigate(['/nautica/tour_info']).then();
  }

  onBook(event, nautPack) {
    event.stopPropagation();
    //
    const reservation = new NauticalReservationModel();
    reservation.nautical_package = nautPack;
    this._nautService.setReservation(reservation);
    this._router.navigate(['/nautica_booking']).then();
  }
}
