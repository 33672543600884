import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CountryModel, UserModel} from '../../models';
import {INTEGER_VALIDATOR} from '../../core/util/form-validators.util';
import {CommonService} from '../../services/common.service';
import {LoginService} from '../../services/login.service';
import {HotelService} from '../../services/hotel.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {NauticalService} from '../../services/nautical.service';
import Swal from 'sweetalert2';
import {GeneralUtil} from '../../core/util/general.util';

@Component({
  selector: 'app-nautical-booking-guest',
  templateUrl: './nautical-booking-guest.component.html',
  styleUrls: ['./nautical-booking-guest.component.css']
})
export class NauticalBookingGuestComponent implements OnInit {

  subscriptionObject: Subscription;
  subscriptionLogin: Subscription;

  guestForm: FormGroup;
  countries: CountryModel[];
  userLogged: UserModel;
  useQrooInfo: number;
  formSelected: number = 1;
  disableInput: boolean = false;
  book_info: UserModel;
  @ViewChild('checkedUser') checkedUserComponent: ElementRef;

  reservation: NauticalReservationModel;

  nautical_package: NauticalModel;

  constructor(
    private _commonService: CommonService,
    private _loginService: LoginService,
    private _hotelService: HotelService,
    private _router: Router,
    private _userService: UserService,
    private _spinner: NgxSpinnerService,
    private _nautService: NauticalService) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();
    this.subscriptionLogin = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.checkingData();
    this.listenLogin();

    this.getPhoneCodes();

    if (this.userLogged) {
      this.formSelected = 2;
      this.disableInput = true;
      this.fillForm(this.userLogged);
    } else {
      this.formSelected = 1;
      this.disableInput = false;
      if (this.book_info) {
        this.fillForm(this.book_info);
      } else {
        this.fillForm(null);
      }
    }
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this.onSlider(1);
            break;
          default:
            this.userLogged = data;
            this.onSlider(2);
            break;
        }
      })
    );
  }

  getPhoneCodes() {
    this.subscriptionObject.add(this._commonService.getPhoneCodes().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }

  checkingData() {
    this.reservation = this._nautService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }

    this.nautical_package = this.reservation.nautical_package;

    switch (this.nautical_package) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }
  }

  goLoginOrRegister() {
    Swal.fire({
      confirmButtonText: 'CANCEL',
      html: '<h3 class="title text-c4 tac text-uc mb-2">Please</h3>' +
        '<button id="login" class="btn btn-nor btn-2nd full-w iblock">' +
        'LOG IN</button>' +
        '<h3 class="title text-c4 tac text-uc py-2">or</h3>' +
        '<div class="rounded bg-c4 p-2">' +
        '<button id="register" class="btn btn-nor btn-3rd full-w iblock">REGISTER</button>' +
        '<p class="font-round mt-2 text-w">Most high-end experience<br>at the most affordable price.</p>' +
        '</div>',
      customClass: {
        confirmButton: 'btn btn-sm btn-5th full-w'
      },
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const login = $('#login');
        const register = $('#register');

        login.addEventListener('click', () => {
          this._router.navigate(['/login'], {queryParams: {redirect: 'nautica_booking_guest'}}).then();
          Swal.close();
        });

        register.addEventListener('click', () => {
          this._router.navigate(['/register'], {queryParams: {redirect: 'nautica_booking_guest'}}).then();
          Swal.close();
        });

      },
      onClose: () => {
        this.checkedUserComponent.nativeElement.click();
      }
    }).then();
  }

  onSlider(id) {
    this.formSelected = id;
    if (this.formSelected === 1) {
      this.disableInput = false;
      this.enableInputs();
      this.fillForm(null);
    } else {
      if (this.userLogged) {
        this.disableInput = true;
        this.disableInputs();
        this.fillForm(this.userLogged);
      } else {
        this.goLoginOrRegister();
      }
    }
  }

  fillForm(user: UserModel) {
    if (this.formSelected === 1) {
      user = null;
    }
    let country = null;
    let area = null;
    let number = null;
    if (user) {
      const phoneNumberArray = user.phone_number.split('-');
      country = Number(phoneNumberArray[0]);
      area = Number(phoneNumberArray[1]);
      number = Number(phoneNumberArray[2]);
    }

    this.guestForm = new FormGroup({
      'name': new FormControl(user ? user.name : null, [Validators.minLength(1)]),
      'last_name': new FormControl(user ? user.last_name : null, [Validators.required]),
      'email': new FormControl(user ? user.email : null, [Validators.required]),
      'phone_number': new FormGroup({
        'countrysel': new FormControl(country),
        'country': new FormControl(country, [Validators.required]),
        'area': new FormControl(area, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(1), Validators.maxLength(3)]),
        'number': new FormControl(number, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(5), Validators.maxLength(7)]),
      }),
    });

    if (this.disableInput === true) {
      this.disableInputs();
    }

  }

  disableInputs() {
    this.guestForm.get('name').disable();
    this.guestForm.get('last_name').disable();
    this.guestForm.get('email').disable();
    this.guestForm.get('phone_number').disable();
  }

  enableInputs() {
    this.guestForm.get('name').enable();
    this.guestForm.get('last_name').enable();
    this.guestForm.get('email').enable();
    this.guestForm.get('phone_number').enable();
  }


  findInvalidControls() {
    const controls = this.guestForm.controls;
    for (const nameC in controls) {
      if (controls[nameC].invalid) {
      }
    }
  }

  onSubmit() {
    this.enableInputs();
    if (this.guestForm.valid) {
      const u = new UserModel();
      const model = Object.assign({}, u, this.guestForm.value);
      model.phone_number = model.phone_number.country + '-' + model.phone_number.area + '-' + model.phone_number.number;
      model.type = this.formSelected;

      this._spinner.show().then();
      this.subscriptionObject.add(this._userService.registerGuest(model).subscribe(
        (data) => {
          this._spinner.hide().then();
          const reservation = this._nautService.getReservation();
          reservation.user_id = data.data.id;
          this._nautService.setReservation(reservation);
          this._router.navigate(['/nautica_booking_details']).then();
        }, (error) => {
          GeneralUtil.errorMessage(error.error.message);
          this._spinner.hide().then();
        })
      );

    } else {
      this.disableInputs();
      this.findInvalidControls();
      GeneralUtil.validateAllFormFields(this.guestForm);
    }
  }

  onChange() {
    const valSelect = this.guestForm.get('phone_number').value.countrysel;
    this.guestForm.patchValue({
      phone_number: {country: valSelect},
    });
  }

}
