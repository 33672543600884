import {Component, OnInit} from '@angular/core';
import {GuestModel, HotelModel, OfferModel, SearchModel} from '../../../models';
import {HotelService} from '../../../services/hotel.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {GeneralUtil} from '../../../core/util/general.util';
import {TEXT_VALIDATOR} from '../../../core/util/form-validators.util';


declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'app-w-hotel-add-guests',
  templateUrl: './w-hotel-add-guests.component.html',
  styleUrls: ['./w-hotel-add-guests.component.css']
})
export class WHotelAddGuestsComponent implements OnInit {

  search: SearchModel = null;
  hotel: HotelModel = null;
  offer: OfferModel = null;
  guests: GuestModel[] = null;
  guestsform: FormGroup;

  constructor(private _hotelService: HotelService) {

  }

  get itemsRooms(): FormArray {
    return this.guestsform.get('rooms') as FormArray;
  }

  ngOnInit() {
    this.onOpenWindow();
  }

  onOpenWindow() {
    this._hotelService._fireOpenAddGuestWindow.subscribe(
      (data) => {
        this.search = this._hotelService.getReservation().search;
        this.offer = this._hotelService.getReservation().offer;
        this.hotel = this._hotelService.getReservation().hotel;
        this.guests = this._hotelService.getReservation().guests;
        if (!this.guests || this.guests.length === 0) {
          this.guests = [];
          this.fillForm('create');
        } else {
          this.fillForm('update');
        }


      }
    );

  }

  fillForm(action: string = 'create') {
    let rooms: FormGroup[] = [];
    if (action === 'create') {
      rooms = this.onCreate(rooms);
    } else {
      rooms = this.onUpdate(rooms);
    }

    this.guestsform = new FormGroup({
      rooms: new FormArray(rooms)
    });

  }


  onCreate(rooms) {
    for (let i = 0; i < this.search.rooms_number; i++) {
      const adults: FormGroup[] = [];
      for (let j = 0; j < this.search.rooms_persons[i].adult; j++) {
        adults.push(new FormGroup({
          'title': new FormControl(null, Validators.required),
          'name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'last_name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
        }));
      }

      const childrens: FormGroup[] = [];
      for (let j = 0; j < this.search.rooms_persons[i].child; j++) {
        childrens.push(new FormGroup({
          'title': new FormControl('MS', Validators.required),
          'name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'last_name': new FormControl(null, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'age': new FormControl(this.search.rooms_persons[i]['age' + (j + 1)], Validators.required),
        }));
      }

      rooms.push(new FormGroup({
        'name': new FormControl(this.offer.room_type_array.length == 1 ? this.offer.room_type : this.offer.room_type_array[i], Validators.required),
        'adult_number': new FormControl(this.search.rooms_persons[i].adult, Validators.required),
        'child_number': new FormControl(this.search.rooms_persons[i].child, Validators.required),
        'adults': new FormArray(adults),
        'childrens': new FormArray(childrens)
      }));

    }

    return rooms;
  }

  onUpdate(rooms) {
    for (let i = 0; i < this.guests.length; i++) {
      const adults: FormGroup[] = [];
      for (let j = 0; j < this.guests[i].adults.length; j++) {
        adults.push(new FormGroup({
          'title': new FormControl(this.guests[i].adults[j].title, Validators.required),
          'name': new FormControl(this.guests[i].adults[j].name, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'last_name': new FormControl(this.guests[i].adults[j].last_name, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
        }));
      }

      const childrens: FormGroup[] = [];
      for (let j = 0; j < this.guests[i].childrens.length; j++) {
        childrens.push(new FormGroup({
          'name': new FormControl(this.guests[i].childrens[j].name, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'last_name': new FormControl(this.guests[i].childrens[j].last_name, [Validators.required, TEXT_VALIDATOR, Validators.minLength(2)]),
          'age': new FormControl(this.guests[i].childrens[j].age, Validators.required),
        }));
      }

      rooms.push(new FormGroup({
        'name': new FormControl(this.offer.room_type_array.length == 1 ? this.offer.room_type : this.offer.room_type_array[i], Validators.required),
        'adult_number': new FormControl(this.guests[i].adults.length, Validators.required),
        'child_number': new FormControl(this.guests[i].childrens.length, Validators.required),
        'adults': new FormArray(adults),
        'childrens': new FormArray(childrens),
      }));
    }

    return rooms;
  }

  onSubmit(event, wID) {
    if (this.guestsform.valid) {
      const reservation = this._hotelService.getReservation();
      reservation.guests = [];
      const rooms = this.guestsform.get('rooms') as FormArray;
      for (let i = 0; i < rooms.controls.length; i++) {
        const guest_info: GuestModel = new GuestModel();
        guest_info.room_type = rooms.controls[i].get('name').value;
        guest_info.adults = [];
        guest_info.childrens = [];

        const adults = rooms.controls[i].get('adults') as FormArray;
        for (let j = 0; j < adults.controls.length; j++) {
          const adult: { title: string, name: string, last_name: string } = {
            title: adults.controls[j].get('title').value,
            name: adults.controls[j].get('name').value,
            last_name: adults.controls[j].get('last_name').value
          };
          guest_info.adults.push(adult);
        }


        const childrens = rooms.controls[i].get('childrens') as FormArray;
        for (let j = 0; j < childrens.controls.length; j++) {
          const child: { title: string, name: string, last_name: string, age: number } = {
            title: 'MS',
            name: childrens.controls[j].get('name').value,
            last_name: childrens.controls[j].get('last_name').value,
            age: childrens.controls[j].get('age').value
          };
          guest_info.childrens.push(child);
        }

        reservation.guests.push(guest_info);
      }
      this._hotelService.setReservation(reservation);
      this._hotelService.onCloseAddGuestWindow();
      closeWindow('', 'w-add-guests');
    } else {
      GeneralUtil.validateAllFormFields(this.guestsform);
    }
    //
  }

  closeW(event, wID) {
    this._hotelService.onCloseAddGuestWindow();
    closeWindow(event, wID);
  }

}
