import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {HotelService} from '../../services/hotel.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ReservationResultModel} from '../../models/reservation-result.model';
import {PrintService} from '../../services/print-service.service';
import {GeneralUtil} from '../../core/util/general.util';

declare function openWindow(event: any, wID: any);

declare function openGallery(event: any);

@Component({
  selector: 'app-mb-hotel',
  templateUrl: './mb-hotel.component.html',
  styleUrls: ['./mb-hotel.component.css']
})
export class MbHotelComponent implements OnInit, OnDestroy {

  reservations: ReservationResultModel[];
  subscriptionObject: Subscription = new Subscription();

  constructor(
    private _hotelService: HotelService,
    private _spinner: NgxSpinnerService,
    private _printService: PrintService
  ) {
  }

  ngOnInit() {
    this.getReservations();
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  getReservations() {
    this._spinner.show().then();
    this.subscriptionObject.add(this._hotelService.getReservations().subscribe(
      (data) => {
        this._spinner.hide().then();
        this.reservations = data.data.reverse();
      }, (error) => {
        this._spinner.hide().then();
        if (error.error.message) {
          GeneralUtil.errorMessage(error.error.message);
        }
        if (error.message) {
          GeneralUtil.errorMessage(error.message);
        }
      }
    ));
  }

  getAccommodation(i) {
    const res = this.reservations[i];
    let name = '';
    res.reservationRooms.map((r, index) => {
      if (index === 0) {
        name = r.type;
      } else {
        name += ', ' + r.type;
      }
    });
    return name;
  }

  getAdults(i) {
    const res = this.reservations[i];
    let adults = 0;
    res.reservationRooms.map((rr, index) => {
      rr.reservationRoomPersons.map((rrp, rIndex) => {
        if (rrp.type === 'ADT') {
          adults += 1;
        }
      });
    });
    return adults;
  }

  getChildren(i) {
    const res = this.reservations[i];
    let children = 0;
    res.reservationRooms.map((rr, index) => {
      rr.reservationRoomPersons.map((rrp, rIndex) => {
        if (rrp.type === 'CHD') {
          children += 1;
        }
      });
    });
    return children;
  }

  onOpenMap(event, i) {
    event.stopPropagation();
    const hotelSelected = this.reservations[i].hotel;
    this._hotelService.onOpenMapWindow(hotelSelected);
    document.getElementById('window-cover').classList.add('cover-block');
    openWindow(event, 'w-map');
  }

  onOpenGallery(event, i) {
    return;
    // event.stopPropagation();
    // const reserve = this.reservations[i];
    // this._hotelService.onOpenGalleryWindow(reserve);
    // openGallery(event);
  }

  pdf(i) {
    const res = this.reservations[i];
    this._hotelService.printReservation(res.id).subscribe(
      (data) => {
        window.open(data.data);
      }
    );

  }

  print(i) {
    const res = this.reservations[i];
    this._printService.printReservation(res);
  }

}
