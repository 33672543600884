import {Component, OnInit} from '@angular/core';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {NauticalService} from '../../services/nautical.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-book-nautical',
  templateUrl: './book-nautical.component.html',
  styleUrls: ['./book-nautical.component.css']
})
export class BookNauticalComponent implements OnInit {

  reservation: NauticalReservationModel;

  nautical_package: NauticalModel;

  constructor(
    private _router: Router,
    private _nautService: NauticalService) {
  }

  ngOnInit() {
    this.reservation = this._nautService.getReservation();
    this.nautical_package = this.reservation.nautical_package;

    this.checkingData();
  }

  checkingData() {
    this.reservation = this._nautService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }

    this.nautical_package = this.reservation.nautical_package;

    switch (this.nautical_package) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }
  }

}
