import {Injectable} from '@angular/core';
import {ApiService} from '../core/api/api.service';
import {RequestParams} from '../core/api/api.model';
import {Observable} from 'rxjs';
import {apiroutes} from '../api-routing';

@Injectable()
export class CommonService {

  /*   _incomplit: Subject<string> = new Subject<string>();
    _refreshAdminView: Subject<any> = new Subject<any>(); */

  constructor(
    private _apiService: ApiService
  ) {
  }

  getPhoneCodes(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiroutes.r_common_phone_code, params);
  }

  getCountries(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiroutes.r_common_countries, params);
  }

  getDestinations(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiroutes.r_common_city_destinations, params);
  }


  /* add(supply: SupplyModel) {
    return this._apiService.post(apiroutes.r_supplies_post, supply).map(
      (data) => {
        return data;
      }
    );
  } */

  /* updateOldOneCreateNew(supply: SupplyModel) {
    return this._apiService.post(apiroutes.r_supplies_close_old_one_open_new, supply).map(
      (data) => {
        return data;
      }
    );
  } */


  /* changeStatus(supply: SupplyModel, newStatus) {
    var params: RequestParams = {
      params: {
        id: supply.id
      }
    }
    var body: RequestBody = {
      status: newStatus
    }

    return this._apiService.patch(apiroutes.r_supplies_change_status, body, params).map(
      (data) => {
        return data;
      });
  } */


}
