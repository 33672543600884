import {Component, OnInit} from '@angular/core';

declare function initPageGallery(ID: any);

declare function hgClear(): any;

@Component({
  selector: 'app-home-gallery',
  templateUrl: './home-gallery.component.html',
  styleUrls: ['./home-gallery.component.css']
})
export class HomeGalleryComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    var sto = setTimeout(function () {
      initPageGallery('home');
    }, 1500);
  }

  onClickHotel() {
    // In a hotels con una búsqueda específica con los 4 hoteles
  }

  ngOnDestroy() {
    hgClear();
  }

}
