import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-hotel-preview',
  templateUrl: './hotel-preview.component.html',
  styleUrls: ['./hotel-preview.component.css']
})
export class HotelPreviewComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
