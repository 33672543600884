import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryModel, HotelModel, OfferModel, ReservationModel, SearchModel, UserModel} from '../../models';
import {INTEGER_VALIDATOR} from '../../core/util/form-validators.util';
import {Subscription} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {GeneralUtil} from '../../core/util/general.util';
import {LoginService} from '../../services/login.service';
import {HotelService} from '../../services/hotel.service';
import {UserService} from '../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {RequestParams} from '../../core/api/api.model';

declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-book-hotel-guest',
  templateUrl: './book-hotel-guest.component.html',
  styleUrls: ['./book-hotel-guest.component.css']
})
export class BookHotelGuestComponent implements OnInit {

  subscriptionObject: Subscription;
  subscriptionLogin: Subscription;
  bookingValuationSubscription: Subscription;

  guestForm: FormGroup;
  countries: CountryModel[];
  userLogged: UserModel;
  useQrooInfo: number;
  formSelected: number = 1;
  search: SearchModel;
  hotel: HotelModel;
  numberOfPersons: number = 0;
  @ViewChild('checkedUser') checkedUserComponent: ElementRef;

  reservation: ReservationModel;
  offer: OfferModel;
  book_info: UserModel;
  disableInput: boolean = false;

  constructor(
    private _commonService: CommonService,
    private _loginService: LoginService,
    private _hotelService: HotelService,
    private _router: Router,
    private _userService: UserService,
    private _spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();
    this.subscriptionLogin = new Subscription();
    this.bookingValuationSubscription = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.checkingData();
    this.listenLogin();

    this.getPhoneCodes();

    this.listenCloseWindowAddGuests();
    this.getNumberOfGuests();

    if (this.userLogged) {
      this.formSelected = 2;
      this.disableInput = true;
      this.fillForm(this.userLogged);
    } else {
      this.formSelected = 1;
      this.disableInput = false;
      if (this.book_info) {
        this.fillForm(this.book_info);
      } else {
        this.fillForm(null);
      }
    }
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this.onSlider(1);
            break;
          default:
            this.userLogged = data;
            this.onSlider(2);
            break;
        }
      })
    );
  }

  checkingData() {
    this.reservation = this._hotelService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        if (!GeneralUtil.isListHotelsAlive()) {
          this.searchExpireMessage();
          this._router.navigate(['/hotel.search']).then();
          return;
        }
        break;
    }

    this.search = this.reservation.search;

    switch (this.search) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.hotel = this.reservation.hotel;

    switch (this.hotel) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.offer = this.reservation.offer;

    switch (this.offer) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }
  }

  listenCloseWindowAddGuests() {
    this._hotelService._fireCloseAddGuestWindow.subscribe(
      () => {
        this.getNumberOfGuests();
      }
    );
  }

  disableInputs() {
    this.guestForm.get('name').disable();
    this.guestForm.get('last_name').disable();
    this.guestForm.get('email').disable();
    this.guestForm.get('phone_number').disable();
  }

  enableInputs() {
    this.guestForm.get('name').enable();
    this.guestForm.get('last_name').enable();
    this.guestForm.get('email').enable();
    this.guestForm.get('phone_number').enable();
  }

  onSlider(id) {
    this.formSelected = id;
    if (this.formSelected === 1) {
      this.disableInput = false;
      this.enableInputs();
      this.fillForm(null);
    } else {
      if (this.userLogged) {
        this.disableInput = true;
        this.disableInputs();
        this.fillForm(this.userLogged);
      } else {
        this.goLoginOrRegister();
      }
    }
  }

  goLoginOrRegister() {
    Swal.fire({
      confirmButtonText: 'CANCEL',
      html: '<h3 class="title text-c4 tac text-uc mb-2">Please</h3>' +
        '<button id="login" class="btn btn-nor btn-2nd full-w iblock">' +
        'LOG IN</button>' +
        '<h3 class="title text-c4 tac text-uc py-2">or</h3>' +
        '<div class="rounded bg-c4 p-2">' +
        '<button id="register" class="btn btn-nor btn-3rd full-w iblock">REGISTER</button>' +
        '<p class="font-round mt-2 text-w">Most high-end experience<br>at the most affordable price.</p>' +
        '</div>',
      customClass: {
        confirmButton: 'btn btn-sm btn-5th full-w'
      },
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const login = $('#login');
        const register = $('#register');

        login.addEventListener('click', () => {
          this._router.navigate(['/login'], {queryParams: {redirect: 'hotel_booking_guest'}}).then();
          Swal.close();
        });

        register.addEventListener('click', () => {
          this._router.navigate(['/register'], {queryParams: {redirect: 'hotel_booking_guest'}}).then();
          Swal.close();
        });

      },
      onClose: () => {
        this.checkedUserComponent.nativeElement.click();
      }
    }).then();
  }

  onAddGuests(event, wID) {
    this._hotelService.onOpenAddGuestWindow();
    openWindow(event, wID);
  }

  getPhoneCodes() {
    this.subscriptionObject.add(this._commonService.getPhoneCodes().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }


  findInvalidControls() {
    const controls = this.guestForm.controls;
    for (const nameC in controls) {
      if (controls[nameC].invalid) {
      }
    }
  }

  checkDuplicateNames() {
    const reservation = this._hotelService.getReservation();
    const namesList = [];
    for (const room of reservation.guests) {
      for (const adults of room.adults) {
        namesList.push(adults.name.replace(/\s+/g, '') + adults.last_name.replace(/\s+/g, ''));
      }
      for (const childrens of room.childrens) {
        namesList.push(childrens.name.replace(/\s+/g, '') + childrens.last_name.replace(/\s+/g, ''));
      }
    }
    let counter = 0;
    for (const name of namesList) {
      counter = 0;
      for (const dup of namesList) {
        if (name === dup) {
          counter++;
        }
      }
      if (counter > 1) {
        return true;
      }
    }
    return false;
  }

  minLengthNames() {
    const reservation = this._hotelService.getReservation();
    for (const room of reservation.guests) {
      for (const adults of room.adults) {
        if (adults.name.length < 3 || adults.last_name.length < 3) {
          return true;
        }
      }
      for (const childrens of room.childrens) {
        if (childrens.name.length < 3 || childrens.last_name.length < 3) {
          return true;
        }
      }
    }
    return false;
  }

  checkValuation(model) {
    if (this.reservation.hotel.provider.id === 1) {
      if (this.reservation.booking_valuation) {
        // Continue with booking
        this.submitProcess(model);
        return true;
      }
      const params: RequestParams = {
        search: {
          hotel_search_code: this.reservation.offer.hotel_search_code,
          check_in: this.reservation.search.check_in
        }
      };
      this._spinner.show().then();
      this.bookingValuationSubscription.add(this._hotelService.checkGGTBookingValuation(params).subscribe(
        (data) => {
          this._spinner.hide().then();
          if (data.data.base_price) {
            this.reservation.offer.base_price = data.data.base_price;
            this.reservation.offer.total_price = data.data.total_price;
          }
          this.reservation.offer.remark = data.data.Remarks;
          const cancelDate = data.data.CancellationDeadline.split('-');
          const cancelDateFormatted = cancelDate[2] + '-' + cancelDate[1] + '-' + cancelDate[0];
          this.reservation.offer.cxl_deadline = cancelDateFormatted;
          this.offer.cxl_deadline = cancelDateFormatted;
          // Continue with booking
          this.reservation.booking_valuation = true;
          this.submitProcess(model);
          return true;
        }, (err) => {
          this._spinner.hide().then();
          if (err.status !== 401) {
            if (err.error.message) {
              GeneralUtil.errorMessage(err.error.message);
              return false;
            }
            if (err.message) {
              GeneralUtil.errorMessage(err.message);
              return false;
            }
          }
        }
      ));
    } else {
      if (this.reservation.booking_valuation) {
        // Continue with booking
        this.submitProcess(model);
        return true;
      }

      const rooms_persons = [];
      for (let i = 0; i < this.reservation.search.rooms_persons.length; i++) {
        const ages = [];
        for (let k = 0; k < this.reservation.search.rooms_persons[i].child; k++) {
          ages.push(this.reservation.search.rooms_persons[i]['age' + (k + 1)]);
        }
        rooms_persons.push({
          adults: this.reservation.search.rooms_persons[i].adult,
          childrens: this.reservation.search.rooms_persons[i].child,
          ages: ages,
        });

      }

      const params: RequestParams = {
        search: {
          check_in: this.reservation.search.check_in,
          check_out: this.reservation.search.check_out,
          contract_id: this.reservation.offer.contract_id,
          hdo_id: this.reservation.hotel.hotel_code,
          interface_info: this.reservation.hotel.interface_info,
          market_id: this.reservation.offer.market_id,
          meal_plan: this.reservation.offer.meal_plan,
          rooms_persons: JSON.stringify(rooms_persons),
          room_type: this.reservation.offer.room_type,
          rate_key: this.reservation.offer.rate_key
        }
      };
      this._spinner.show().then();
      this.bookingValuationSubscription.add(this._hotelService.checkHDOBookingValuation(params).subscribe(
        (data) => {
          this._spinner.hide().then();
          if (data.data.base_price) {
            this.reservation.offer.base_price = data.data.base_price;
            this.reservation.offer.total_price = data.data.total_price;
          }
          const cancelDate = data.data.CancellationDeadline.split('-');
          const cancelDateFormatted = cancelDate[2] + '-' + cancelDate[1] + '-' + cancelDate[0];
          this.reservation.offer.cxl_deadline = cancelDateFormatted;
          this.offer.cxl_deadline = cancelDateFormatted;
          // Continue with booking
          this.reservation.booking_valuation = true;
          this.submitProcess(model);
          return true;
        }, (err) => {
          this._spinner.hide().then();
          if (err.status !== 401) {
            if (err.error.message) {
              GeneralUtil.errorMessage(err.error.message);
              return false;
            }
            if (err.message) {
              GeneralUtil.errorMessage(err.message);
              return false;
            }
          }
        }
      ));
    }
  }

  submitProcess(model) {
    this._spinner.show().then();
    this.subscriptionObject.add(this._userService.registerGuest(model).subscribe(
      (data) => {
        const reservation = this._hotelService.getReservation();
        reservation.book_info = data.data;
        reservation.use_qroo_info = this.formSelected;
        this._hotelService.setReservation(reservation);
        this._spinner.hide().then();
        this._router.navigate(['/payment']).then();
      }, (error) => {
        GeneralUtil.errorMessage(error.error.message);
        this._spinner.hide().then();
      })
    );
  }

  onSubmit() {
    this.enableInputs();
    if (this.guestForm.valid) {
      if (this.checkDuplicateNames()) {
        GeneralUtil.errorMessage('Duplicate Guests!');
        return;
      }
      if (this.minLengthNames()) {
        GeneralUtil.errorMessage('All guests requires a minimum length of 3 characters for Name and/or Last Name!');
        return;
      }
      if (!this.checkListOfPersons()) {
        return;
      }
      const u = new UserModel();
      const model = Object.assign({}, u, this.guestForm.value);
      model.phone_number = model.phone_number.country + '-' + model.phone_number.area + '-' + model.phone_number.number;
      model.type = this.formSelected;

      this.checkValuation(model);

    } else {
      this.disableInputs();
      this.findInvalidControls();
      GeneralUtil.validateAllFormFields(this.guestForm);
    }
  }

  checkListOfPersons() {
    const guests = this._hotelService.getReservation().guests;
    if (!guests || guests.length === 0) {
      Swal.fire({
        type: 'warning',
        title: 'Please add guest information',
        text: 'You have to fill the guests information, click \'Add Guests\' button to do that.',
      }).then();
      // A la fuerza
      const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
      tBody.classList.remove('swal2-height-auto');
      return false;
    }

    return true;

  }

  onChange() {
    const valSelect = this.guestForm.get('phone_number').value.countrysel;
    this.guestForm.patchValue({
      phone_number: {country: valSelect},
    });
  }

  fillForm(user: UserModel) {
    if (this.formSelected === 1) {
      user = null;
    }
    let country = null;
    let area = null;
    let number = null;
    if (user) {
      const phoneNumberArray = user.phone_number.split('-');
      country = Number(phoneNumberArray[0]);
      area = Number(phoneNumberArray[1]);
      number = Number(phoneNumberArray[2]);
    }

    this.guestForm = new FormGroup({
      'name': new FormControl(user ? user.name : null, [Validators.minLength(1)]),
      'last_name': new FormControl(user ? user.last_name : null, [Validators.required]),
      'email': new FormControl(user ? user.email : null, [Validators.required]),
      'phone_number': new FormGroup({
        'countrysel': new FormControl(country),
        'country': new FormControl(country, [Validators.required]),
        'area': new FormControl(area, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(1), Validators.maxLength(3)]),
        'number': new FormControl(number, [Validators.required, INTEGER_VALIDATOR, Validators.minLength(5), Validators.maxLength(7)]),
      }),
    });

    if (this.disableInput === true) {
      this.disableInputs();
    }

  }

  searchCountryByCode(code) {
    this.countries.map(
      (c, i) => {
        if (c.phone_country_code === code) {
          return c;
        }
      }
    );
  }


  getNumberOfGuests() {
    this.numberOfPersons = 0;
    const guests = this._hotelService.getReservation().guests;
    guests.map(
      (g, index) => {
        this.numberOfPersons += g.adults.length + g.childrens.length;
      }
    );
  }

  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        const reservation = this._hotelService.getReservation();
        reservation.automatic_refresh = true;
        this._hotelService.setReservation(reservation);
        this._router.navigate(['/hotel_search']).then();
      }
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

}
