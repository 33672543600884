import {Component, OnInit} from '@angular/core';

declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'app-w-event-detail',
  templateUrl: './w-event-detail.component.html',
  styleUrls: ['./w-event-detail.component.css']
})
export class WEventDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  closeW(event, wID) {
    closeWindow(event, wID);
  }

  onGoEvent($event, wID) {
    closeWindow(event, wID);
    //
  }
}
