import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ReservationResultModel} from '../models/reservation-result.model';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  reservation: ReservationResultModel = null;


  _onTicketSubmitted: Subject<any> = new Subject<any>();

  constructor(private router: Router) {

  }

  setReservation(res) {
    this.reservation = res;
  }

  getReservation() {
    return this.reservation;
  }

  clearReservation() {
    this.reservation = new ReservationResultModel();
  }

  printReservation(res: ReservationResultModel) {
    this.isPrinting = true;
    this.setReservation(res);
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print']
        }
      }]);
  }

  onReady() {
    window.print();
    this.isPrinting = false;
    this.router.navigate([{outlets: {print: null}}]);
  }


}

