import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SplashComponent} from './splash/splash.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {CalendarComponent} from './calendar/calendar.component';
import {HomeComponent} from './home/home.component';
// HOTEL
import {HotelSearchComponent} from './hotel/hotel-search/hotel-search.component';
import {HotelsComponent} from './hotel/hotels/hotels.component';
import {BookHotelComponent} from './booking/book-hotel/book-hotel.component';
import {BookHotelGuestComponent} from './booking/book-hotel-guest/book-hotel-guest.component';
// NAUTICA
import {NauticalComponent} from './nautica/nautica/nautical.component';
import {BookNauticalComponent} from './booking/book-nautica/book-nautical.component';
import {NauticalBookingGuestComponent} from './booking/nautica-booking-guest/nautical-booking-guest.component';
import {NauticalBookingDetailsComponent} from './booking/nautica-booking-details/nautical-booking-details.component';
import { TourNauticalComponent } from './nautica/tour-nautica/tour-nautical.component';
// TOURS
import {ToursSearchComponent} from './tour/tours-search/tours-search.component';
import {ToursComponent} from './tour/tours/tours.component';

import {PaymentComponent} from './payment/payment/payment.component';
import {PaymentApprovedComponent} from './payment/payment-approved/payment-approved.component';

import {MyBookingsComponent} from './my-bookings/my-bookings.component';

import {P404Component} from './p404/p404.component';
import {UserGuard} from './services/guards/user-guard.service';

import {PUnderConstructionComponent} from './p-under-construction/p-under-construction.component';


import {BookingConfirmationEnComponent} from './html-docs/booking-confirmation-en/booking-confirmation-en.component';
import {RegisterMailEnComponent} from './html-docs/register-mail-en/register-mail-en.component';
// import { BookingConfirmationMailEnComponent } from './html-docs/booking-confirmation-mail-en/booking-confirmation-mail-en.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},

  {path: 'welcome', component: SplashComponent},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'calendar', component: CalendarComponent},
  {path: 'my_bookings', component: MyBookingsComponent, canActivate: [UserGuard]},
  {path: 'home', component: HomeComponent},

  // HOTELS
  {path: 'hotel_search', component: HotelSearchComponent},
  {path: 'hotels', component: HotelsComponent},
  {path: 'hotel/quotations/:quoteID/:offerID', component: BookHotelComponent},
  {path: 'hotel_booking', component: BookHotelComponent},
  {path: 'hotel_booking_guest', component: BookHotelGuestComponent},
  /*
  { path: 'hotel_booking_confirmation', component: ConfirmHotelComponent },
  { path: 'hotel_booking_address', component: BookNauticalComponent },
   */

  // TOURS
  {path: 'nautica', component: NauticalComponent},
  {path: 'nautica/tour_info', component: TourNauticalComponent},
  {path: 'nautica_booking', component: BookNauticalComponent},
  {path: 'nautica_booking_guest', component: NauticalBookingGuestComponent},
  {path: 'nautica_booking_details', component: NauticalBookingDetailsComponent},
  // TOURS
  {path: 'tours_search', component: ToursSearchComponent},
  {path: 'tours', component: ToursComponent},

  {path: 'payment', component: PaymentComponent},
  {path: 'payment_approved', component: PaymentApprovedComponent},

  {path: 'uc', component: PUnderConstructionComponent},

  {path: '404', component: P404Component},
  {
    path: 'print',
    outlet: 'print',
    component: BookingConfirmationEnComponent
  },
  {path: 'print1', component: BookingConfirmationEnComponent},
  {path: 'print2', component: RegisterMailEnComponent},

  {path: '**', redirectTo: 'home', pathMatch: 'full'}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
