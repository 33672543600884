import {Component, OnInit} from '@angular/core';

declare function calendarAnchos(event: any);

declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    //
    calendarAnchos(event);
  }

  onAddEvent() {
    //
  }

  onAccomodationDetail(event) {
    //
    openWindow(event, 'w-accomodation-detail');
  }

  onEventDetail(event) {
    //
    openWindow(event, 'w-event-detail');
  }

}
