export const apiroutes = {
  //Auth
  r_user_auth_login: 'user/auth/login_client',
  r_user_auth_login_refresh: 'user/auth/login/refresh',
  r_user_users_register: 'user/users/register',
  r_user_users_register_guest: 'user/users/register_guest',
  r_user_users_activate_account: 'user/users/activate_account',
  r_user_users_resend_email: 'user/users/resend_email',

  //users
  r_user_auth_logout: 'user/auth/logout',
  r_user_auth_logged_user: 'user/auth/logged_user',

  //commons //countries
  r_common_phone_code: 'common/phone_code_countries',
  r_common_countries: 'common/countries',

  // Booking
  r_ggt_booking_valuation: 'hotel/reservations/ggt/check_booking_valuation',
  r_hdo_booking_valuation: 'hotel/reservations/hdo/check_booking_valuation',


  //destinations
  r_common_city_destinations: 'common/city_destinations',

  //Hotels
  r_hotel_client_hotels: 'hotel_client/hotels',
  r_hotel_client_hotels_show: 'hotel_client/hotels/{id}',

  //Reservation
  r_hotel_reservations_get: 'hotel_client/reservations',
  r_hotel_reservations_get_price_breakdown_get: 'hotel_client/reservations/get_price_breakdown',
  r_hotel_reservations_post: 'hotel/client_reservations',
  r_hotel_export_reservation_to_pdf: 'hotel_client/reservations/export_reservation_to_pdf/{id}',

  //Quote
  r_hotel_quote_get: 'hotel/quotations',

  // Naut
  r_naut_send_confirmation: 'tour/nautica/send_confirmation'


};

