import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tours-search',
  templateUrl: './tours-search.component.html',
  styleUrls: ['./tours-search.component.css']
})
export class ToursSearchComponent implements OnInit {

  /*  d: Date = new Date();

   myOptions: INgxMyDpOptions = {
     // other options...
     dateFormat: 'dd/mm/yyyy',
     markCurrentDay: true,
     disableUntil: { year: this.d.getFullYear(), month: this.d.getMonth() + 1, day: this.d.getDate() },
        highlightDates : [{year: 2019, month: 4, day: 28}, {year: 2019, month: 4, day: 29}],
       markDates:[{dates: [{year: 2019, month: 4, day: 26},{year: 2019, month: 4, day: 27}], color: '#004198'}, {dates: [{year: 2019, month: 4, day: 28}, {year: 2019, month: 4, day: 29}], color: 'green'}]
   }; */
  constructor(private _router: Router) {
  }

  ngOnInit() {
  }

  // - - - - - - - - - - - - - - DATEPICKER CLOSE BUTTON
  onDatePicker0() {
    document.getElementById('top-menu').style.zIndex = '-1';
    var pt = document.getElementsByClassName('page-title')[0] as HTMLDivElement;
    pt.style.zIndex = '-1';
    document.getElementById('closeDP0').style.display = 'block';
  }

  onCloseClose(bID) {
    document.getElementById('top-menu').style.zIndex = '10';
    var pt = document.getElementsByClassName('page-title')[0] as HTMLDivElement;
    pt.style.zIndex = 'initial';
    document.getElementById('closeDP' + bID).style.display = 'none';
  }


}
