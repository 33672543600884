import { Component, OnInit } from '@angular/core';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {Router} from '@angular/router';
import {NauticalService} from '../../services/nautical.service';

@Component({
  selector: 'app-tour-nautical',
  templateUrl: './tour-nautical.component.html',
  styleUrls: ['./tour-nautical.component.css']
})
export class TourNauticalComponent implements OnInit {

  reservation: NauticalReservationModel;

  nautical_package: NauticalModel;

  constructor(
    private _router: Router,
    private _nautService: NauticalService) { }

  ngOnInit() {
    this.reservation = this._nautService.getReservation();
    this.nautical_package = this.reservation.nautical_package;

    this.checkingData();
  }

  checkingData() {
    this.reservation = this._nautService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }

    this.nautical_package = this.reservation.nautical_package;

    switch (this.nautical_package) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }
  }

  onSelectThis(event) {
  }

  onBack() {
    this._nautService.resetReservation();
    this._router.navigate(['/nautica']).then();
  }

  onDoBook(event, window) {
    this._router.navigate(['/nautica_booking']).then();
  }

  openInNewTab(url) {
    window.open(url, '_blank');
  }

  openUrl(url) {
    window.open(url, '_self');
  }

}
