import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-book-hotel-address',
  templateUrl: './book-hotel-address.component.html',
  styleUrls: ['./book-hotel-address.component.css']
})
export class BookHotelAddressComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  onConfirm() {
    //
    this.router.navigate(['/payment']);
  }

}
