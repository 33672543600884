import {Component, OnInit} from '@angular/core';
import {ReservationResultModel} from '../../models/reservation-result.model';
import {PrintService} from '../../services/print-service.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-booking-confirmation-en',
  templateUrl: './booking-confirmation-en.component.html',
  styleUrls: ['./booking-confirmation-en.component.css']
})
export class BookingConfirmationEnComponent implements OnInit {

  reservation: ReservationResultModel;
  mapUrl: string;

  constructor(private _printService: PrintService, public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.reservation = this._printService.getReservation();
    if (this.reservation) {
      this.mapUrl = '';
      this.mapUrl = 'https://www.google.com/maps/embed/v1/place?q=';
      this.mapUrl += this.reservation.hotel.latitude + ',' + this.reservation.hotel.longitude;
      this.mapUrl += '&key=';
      this.mapUrl += 'AIzaSyDjL19EuV-7T1PQc9IV_a1M6Y2jFvkzMSE';
    }
    //document.getElementsByTagName('body')[0].style.overflow = "visible";
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._printService.onReady();
    }, 1500);
  }
}
