import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PrintService} from '../services/print-service.service';
import {ReservationResultModel} from '../models/reservation-result.model';

@Component({
  selector: 'app-print-component',
  templateUrl: './print-component.component.html',
  styleUrls: ['./print-component.component.css']
})
export class PrintComponentComponent implements OnInit, AfterViewInit {

  reservation: ReservationResultModel;

  constructor(private _printService: PrintService) {
  }

  ngOnInit() {
    this.reservation = this._printService.getReservation();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._printService.onReady();
    }, 1500);
  }

}
