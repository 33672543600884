import {Component, OnInit, ViewChild} from '@angular/core';
import {HotelModel} from '../../../models';
import {Subscription} from 'rxjs';
import {AgmMap} from '@agm/core';
import {HotelService} from '../../../services/hotel.service';

declare function closeWindow(param1: any, param2: any);

@Component({
  selector: 'app-w-map',
  templateUrl: './w-map.component.html',
  styleUrls: ['./w-map.component.css']
})
export class WMapComponent implements OnInit {

  hotelSelected: HotelModel;
  viewMode0 = 'tab-4';
  subscriptionObject: Subscription;
  @ViewChild('map') map: AgmMap;
  mapUrl: string;

  constructor(private _hotelService: HotelService) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();
    this.listenOpenWindow();
  }

  listenOpenWindow() {
    this.subscriptionObject.add(this._hotelService._fireOpenMapWindow.subscribe(
      (data) => {
        this.hotelSelected = data;
        this.mapUrl = '';
        this.mapUrl = 'https://www.google.com/maps/embed/v1/place?q=';
        this.mapUrl += this.hotelSelected.details_data.latitude + ',' + this.hotelSelected.details_data.longitude;
        this.mapUrl += '&key=';
        this.mapUrl += 'AIzaSyDjL19EuV-7T1PQc9IV_a1M6Y2jFvkzMSE';
        /*  setTimeout(() => {
           this.map.triggerResize();
          }, 3000); */
        //this.map.triggerResize();
      }
    ));

  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  onCloseMap(event, wId) {
    //19.7462552,-89.1248006,8
    this.mapUrl = 'https://www.google.com/maps/embed/v1/place?q=19.7462552,-89.1248006&key=AIzaSyDjL19EuV-7T1PQc9IV_a1M6Y2jFvkzMSE';
    closeWindow(event, wId);
    document.getElementById('window-cover').classList.remove('cover-block');
  }

}
