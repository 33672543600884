import {Injectable} from '@angular/core';
import {ApiService} from '../core/api/api.service';
import {RequestParams} from '../core/api/api.model';
import {Observable, Subject} from 'rxjs';
import {map, timeout} from 'rxjs/operators';
import {apiroutes} from '../api-routing';
import {ChildAge, HotelModel, RoomPerson} from '../models';
import {StoreUtil} from '../core/util/store.util';
import {ReservationModel} from '../models/reservation.model';
import {ReservationResultModel} from '../models/reservation-result.model';


@Injectable()
export class HotelService {

  reservation: ReservationModel = null;
  reservationResult: ReservationResultModel = null;

  _fireOpenDetailWindow: Subject<any> = new Subject<any>();
  _fireOpenSetChildsWindow: Subject<any> = new Subject<any>();
  _fireCloseSetChildsWindow: Subject<any> = new Subject<any>();
  _fireOpenAddGuestWindow: Subject<HotelModel> = new Subject<HotelModel>();
  _fireCloseAddGuestWindow: Subject<HotelModel> = new Subject<HotelModel>();
  _fireOpenMapWindow: Subject<HotelModel> = new Subject<HotelModel>();
  _fireOpenGalleryWindow: Subject<any> = new Subject<any>();

  /*_refreshAdminView: Subject<any> = new Subject<any>(); */

  constructor(
    private _apiService: ApiService
  ) {
  }

  static setReservationResult(r: ReservationResultModel) {
    StoreUtil.objectToStore('reservationResult', r);
  }

  static getReservationResult() {
    return StoreUtil.objectFromStore('reservationResult');
  }

  static resetReservationResult() {
    StoreUtil.remove('reservationResult');
  }

  setReservation(r: ReservationModel) {
    StoreUtil.objectToStore('reservation', r);
    this.reservation = r;
  }

  getReservation() {
    this.reservation = StoreUtil.objectFromStore('reservation');
    return this.reservation;

  }

  resetReservation() {
    StoreUtil.remove('reservation');
    this.reservation = null;
  }

  onOpenDetailWindow(hotelSelected: HotelModel, tabId: number = 1) {
    this._fireOpenDetailWindow.next(
      {
        hotel: hotelSelected,
        tabId: tabId
      });
  }

  onOpenSetChildsWindow(roomPerson: RoomPerson) {
    this._fireOpenSetChildsWindow.next(roomPerson);
  }

  onCloseSetChildsWindow(obj: ChildAge) {
    this._fireCloseSetChildsWindow.next(obj);
  }

  onOpenAddGuestWindow() {
    this._fireOpenAddGuestWindow.next(null);
  }

  onCloseAddGuestWindow() {
    this._fireCloseAddGuestWindow.next(null);
  }

  onOpenMapWindow(hotelSelected: HotelModel) {
    this._fireOpenMapWindow.next(hotelSelected);
  }

  onOpenGalleryWindow(reservation: ReservationResultModel) {
    this._fireOpenGalleryWindow.next(reservation);
  }

  getHotels(params): Observable<any> {
    // return this._apiService.get(apiroutes.r_hotel_client_hotels, params).pipe(
    //   timeout(40000)
    // );
    return this._apiService.getLimit(apiroutes.r_hotel_client_hotels, params);
  }

  getHotelDetails(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiroutes.r_hotel_client_hotels_show, params).pipe(
      timeout(40000)
    );
  }

  getPriceBreakDown(hotel_search_code: string): Observable<any> {
    var params: RequestParams = {
      search: {
        hotel_search_code: hotel_search_code
      }
    };
    return this._apiService.get(apiroutes.r_hotel_reservations_get_price_breakdown_get, params);
  }

  makeAReservation(reserv: any) {
    return this._apiService.post(apiroutes.r_hotel_reservations_post, reserv).pipe(
      map(
        (data) => {
          this.reservationResult = data.object;
          return data;
        }
      )
    );
  }

  getReservations(params: RequestParams = {}) {
    return this._apiService.get(apiroutes.r_hotel_reservations_get, params);
  }


  printReservation(id: number) {
    var params: RequestParams = {
      params: {
        id: id
      }
    };
    return this._apiService.get(apiroutes.r_hotel_export_reservation_to_pdf, params);
  }

  getOffer(quoteId, offerId) {
    const finalPath = apiroutes.r_hotel_quote_get + '/' + quoteId + '/' + offerId;
    return this._apiService.getSimple(finalPath);
  }

  checkGGTBookingValuation(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiroutes.r_ggt_booking_valuation, params);
  }

  checkHDOBookingValuation(params: RequestParams = {}): Observable<any> {
    return this._apiService.get(apiroutes.r_hdo_booking_valuation, params);
  }

}
