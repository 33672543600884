import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'CustomCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any): string {
    if (value == null) {
      return '0';
    }
    var valueTransformed = this.currencyPipe.transform(value);
    var partes = valueTransformed.split('.');
    if (partes[partes.length - 1] == '00') {
      return valueTransformed.substr(0, valueTransformed.length - 3);
    }
    return valueTransformed;
  }
}
