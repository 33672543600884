import {Component, OnInit} from '@angular/core';
import {ReservationModel, UserModel} from '../../models';
import {HotelService} from '../../services/hotel.service';
import {ReservationResultModel} from '../../models/reservation-result.model';
import {NgNavigatorShareService} from 'ng-navigator-share';
import {Router} from '@angular/router';
import {PrintService} from '../../services/print-service.service';
import {Subscription} from 'rxjs';
import {LoginService} from '../../services/login.service';


@Component({
  selector: 'app-payment-approved',
  templateUrl: './payment-approved.component.html',
  styleUrls: ['./payment-approved.component.css']
})
export class PaymentApprovedComponent implements OnInit {

  reservation: ReservationModel;
  reservationResult: ReservationResultModel;
  subscriptionLogin: Subscription;
  userLogged: UserModel;

  private ngNavigatorShareService: NgNavigatorShareService;

  constructor(
    private _hotelService: HotelService,
    ngNavigatorShareService: NgNavigatorShareService,
    private _router: Router,
    private _loginService: LoginService,
    private _printService: PrintService
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }

  ngOnInit() {
    this.subscriptionLogin = new Subscription();
    this.userLogged = this._loginService.getUserInfo();
    console.log(this.userLogged);

    this.checkingData();
    this.listenLogin();
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            break;
          default:
            this.userLogged = data;
            break;
        }
      })
    );
  }

  checkingData() {
    this.reservationResult = HotelService.getReservationResult();

    switch (this.reservationResult) {
      case null:
      case undefined:
        HotelService.resetReservationResult();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

  }

  onDownloadPdf() {
    window.open(this.reservationResult.url);
  }

  onPrint() {
    this._printService.printReservation(this.reservationResult);
  }

  async onShare() {
    this.ngNavigatorShareService.share({
      title: this.reservationResult.reservation_code,
      text: 'My reservation',
      url: this.reservationResult.url
    }).then((response) => {
    })
      .catch((error) => {
      });
  }

}
