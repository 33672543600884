import {Component, OnDestroy, OnInit} from '@angular/core';
import {HotelModel, SearchModel} from '../../../models';
import {HotelService} from '../../../services/hotel.service';
import {RequestParams} from '../../../core/api/api.model';
import {Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';

declare function closeWindow(param1: any, param2: any);

@Component({
  selector: 'app-w-about-hotel',
  templateUrl: './w-about-hotel.component.html',
  styleUrls: ['./w-about-hotel.component.css']
})
export class WAboutHotelComponent implements OnInit, OnDestroy {

  hotelSelected: HotelModel;
  viewMode0 = 'tab-4';
  subscriptionObject: Subscription;
  search: SearchModel;

  id = 'idxxx';
  imgsTotal = 5;
  currImg = 0;

  facilities: Array<string>;

  constructor(
    private _hotelService: HotelService,
    private _spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.facilities = [];
    this.currImg = 0;
    this.subscriptionObject = new Subscription();
    this.listenOpenWindow();
    this.search = this._hotelService.getReservation() ? this._hotelService.getReservation().search : null;
  }

  listenOpenWindow() {
    this.subscriptionObject.add(this._hotelService._fireOpenDetailWindow.subscribe(
      (data) => {
        this.hotelSelected = data.hotel;
        this.viewMode0 = 'tab-' + data.tabId;
        this.currImg = 0;
        this.search = this._hotelService.getReservation().search;
        if (!this.hotelSelected.details_data.description) {
          this.getHotelDetails();
        } else {
          console.log(this.hotelSelected.details_data.hotel_facilities);
          if (this.hotelSelected.provider.id === 1) {
            // const temp = this.hotelSelected.details_data.hotel_facilities[0];
            this.facilities = this.hotelSelected.details_data.hotel_facilities;
          }
          if (this.hotelSelected.provider.id === 2) {
            const temp = this.hotelSelected.details_data.hotel_facilities.replace(new RegExp('</br>', 'g'), '|');
            this.facilities = temp.split('|');
          }
          this.imgsTotal = this.hotelSelected.details_data.pictures.length;
        }

      }
    ));

  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  getHotelDetails() {
    var params: RequestParams = {

      params: {
        id: this.hotelSelected.id
      },
      search: {
        id_search_code: this.hotelSelected.offers[0].hotel_search_code
      }
    };
    this._spinner.show();
    this._hotelService.getHotelDetails(params).subscribe(
      (data) => {
        this._spinner.hide();
        this.hotelSelected.details_data = data.data;
        this.imgsTotal = this.hotelSelected.details_data.pictures.length;
        if (this.hotelSelected.provider.id === 1) {
          // const temp = this.hotelSelected.details_data.hotel_facilities[0];
          this.facilities = this.hotelSelected.details_data.hotel_facilities;
        }
        if (this.hotelSelected.provider.id === 2) {
          const temp = this.hotelSelected.details_data.hotel_facilities.replace(new RegExp('</br>', 'g'), '|');
          this.facilities = temp.split('|');
        }
      }, (error) => {
        this._spinner.hide();
        if (error.name && error.name == 'TimeoutError') {
          Swal.fire({
            title: 'A time out error ocurred',
            text: 'The provider is temporarily out of service. Repeat your request.',
            type: 'error',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: ' Repeat request'
          }).then((result) => {
            if (result.value) {
              this.getHotelDetails();
            } else {
              this.closeW(event, 'w-about-hotel');
            }
          });
        }
      }
    );
  }

  onPrevImg(event, gID) {
    /*  event.stopPropagation();
     var gc = document.getElementById(gID) as HTMLDivElement;
     var activeImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    //
    this.currImg--;
    this.currImg = this.currImg < 0 ? this.imgsTotal - 1 : this.currImg;
    /*  var nextImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    /*  activeImg.classList.remove('active');
     setTimeout(() => {
       nextImg.classList.add('active');
     }, 250); */
  }

  onNextImg(event, gID) {
    event.stopPropagation();
    /*  var gc = document.getElementById(gID) as HTMLDivElement;
     var activeImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    //
    this.currImg++;
    this.currImg = this.currImg > this.imgsTotal - 1 ? 0 : this.currImg;
    /*   var nextImg = gc.getElementsByTagName('img')[this.currImg] as HTMLImageElement; */
    /*   activeImg.classList.remove('active'); */
    /* setTimeout(() => {
      nextImg.classList.add('active');
    }, 250); */
  }

  onResize(event, wID, gID) {
    event.stopPropagation();
    //
    var bRsz = event.target as HTMLButtonElement;
    var WC = document.getElementById('window-cover') as HTMLDivElement;
    var BC = document.getElementsByClassName('btn-close-w')[0] as HTMLButtonElement;
    var classBlock = WC.classList;
    //
    if (classBlock.contains('cover-block')) {
      WC.classList.remove('cover-block');
      BC.style.display = 'block';
      bRsz.innerHTML = '<img alt="previous" src="../../assets/images/icon-resize.svg">';
    } else {
      WC.classList.add('cover-block');
      BC.style.display = 'none';
      bRsz.innerHTML = '<img alt="previous" src="../../assets/images/icon-close-w.svg">';
    }
    //
    var wW = document.getElementById(wID) as HTMLDivElement;
    wW.classList.toggle('w-full');
    var wT = document.getElementById(gID) as HTMLDivElement;
    wT.classList.toggle('t-full');
  }


  changeViewMode(el) {
    this.viewMode0 = el;
  }

  closeW(event, wId) {
    this._spinner.hide();
    closeWindow(event, wId);
  }

  onErrorImage(event, i) {
    this.imgsTotal -= 1;
    this.hotelSelected.details_data.pictures = this.hotelSelected.details_data.pictures.filter((element, index) => {
      return i != index;
    });
    if (this.imgsTotal == 0) {
      this.imgsTotal = 1;
    }
  }

}
