import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HotelModel, OfferModel, ReservationModel, SearchModel} from '../../models';
import {HotelService} from '../../services/hotel.service';
import {Subscription} from 'rxjs';
import {RequestParams} from '../../core/api/api.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {GeneralUtil} from '../../core/util/general.util';
import Swal from 'sweetalert2';

declare function togglePannel(event: any);

declare function toggleItems(event);

declare function openWindow(event: any, wID: any);

declare function suma(event: any, max: any);

declare function resta(event: any, min: any);

declare function openGallery(event: any);

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css']
})
export class HotelsComponent implements OnInit, OnDestroy {

  viewMode0 = 'tab-1';
  viewMode1 = 'subtab-1';

  reservation: ReservationModel;
  search: SearchModel = null;
  hotels: HotelModel[];
  subscriptionObject: Subscription;
  message = 'Searching';
  orderStars = 'desc';
  orderCost = 'desc';
  offerSelectedId = '-1';

  text1 = 'SHOW LESS ROOMS <span class="indicator"><img src="../../../assets/images/btn-indicator.svg"></span>';
  text2 = 'SHOW ALL ROOMS <span class="indicator"><img src="../../../assets/images/btn-indicator.svg"></span>';

  constructor(private _router: Router,
              private _hotelService: HotelService,
              private _spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this._spinner.hide();
    this.subscriptionObject = new Subscription();
    this.reservation = this._hotelService.getReservation();
    this.hotels = this.reservation.list_hotels;
    this.search = this.reservation ? this.reservation.search : null;

    if (!this.search) {
      this._router.navigate(['/hotel_search']);
      return;
    }

    if (!GeneralUtil.isListHotelsAlive()) {
      this.searchExpireMessage();
      return;
    }

    this.reservation.offer = null;
    this.reservation.hotel = null;
    this._hotelService.setReservation(this.reservation);
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }


  onTogglePanel(event, i, j) {
    return;
    // @ts-ignore
    const offerSelected = this.hotels[i].offers[j];

    if (!offerSelected.price_break_down && this.hotels[i].provider_id === 1) {
      if (offerSelected.hotel_search_code !== this.offerSelectedId) {
        this.offerSelectedId = offerSelected.hotel_search_code;
        this._spinner.show();
        this.subscriptionObject.add(this._hotelService.getPriceBreakDown(offerSelected.hotel_search_code).subscribe(
          (data) => {
            this.hotels[i].offers[j].price_break_down = data.data;
            this._spinner.hide();
            togglePannel(event);
          }
        ));
      } else {
        this.offerSelectedId = '-1';
        togglePannel(event);
      }
    } else {
      this.offerSelectedId = '-1';
      togglePannel(event);
    }
    this.viewMode1 = 'subtab-1';
  }

  getNights() {
    return GeneralUtil.getNights();
  }

  onToggleItems(event, bID) {
    // SCROLL
    setTimeout(function () {
      var dElem = document.getElementById('main-container') as HTMLDivElement;
      var scrollTarget = document.getElementById('card-' + bID).offsetTop - 100;    //scrollTarget.scrollIntoView();
      dElem.scrollTo(0, scrollTarget);
    }, 300);
    //
    toggleItems(event);
    //
    var btnAll = document.getElementsByClassName('btn-4th-dark');
    const btn = document.getElementById('btn-toggle-' + bID) as HTMLButtonElement;
    //
    for (var i = 0; i < btnAll.length; i++) {
      if (btnAll[i] == btn) {
        // ES
        btn.classList.toggle('active');
        btn.classList.contains('active') ? btn.innerHTML = this.text1 : btn.innerHTML = this.text2;
      } else {
        // NO ES
        btnAll[i].classList.remove('active');
        btnAll[i].innerHTML = this.text2;
      }
    }
    //
  }


  onShowHotelInfo(event, wID, position, tabId: number = 1) {
    const hotelSel = this.hotels[position];
    this._hotelService.onOpenDetailWindow(hotelSel, tabId);
    openWindow(event, wID);
  }

  onBookHotel(i, j) {
    const reservation = this._hotelService.getReservation();
    reservation.hotel = this.hotels[i];
    reservation.offer = this.hotels[i].offers[j];
    reservation.search = this.search;
    reservation.use_qroo_info = null;
    reservation.guests = [];
    reservation.book_info = null;

    this._hotelService.setReservation(reservation);
    this._router.navigate(['/hotel_booking']);
  }

  onOpenGallery(event, wID, numI) {
    event.stopPropagation();
    //     openGallery(event);
    this.onShowHotelInfo(event, wID, numI);
  }

  orderByStars() {
    this.removeArrows();
    const tLi = document.getElementById('filter-li1') as HTMLLIElement;
    if (this.orderStars === 'asc') {
      this.orderStars = 'des';
      tLi.classList.add('asc');
      this.hotels.sort(function (a, b) {
        if (a.stars > b.stars) {
          return 1;
        }
        if (a.stars < b.stars) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    } else {
      this.orderStars = 'asc';
      tLi.classList.add('des');
      this.hotels.sort(function (a, b) {
        if (a.stars < b.stars) {
          return 1;
        }
        if (a.stars > b.stars) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
  }

  orderByCost() {
    this.removeArrows();
    const tLi = document.getElementById('filter-li0') as HTMLLIElement;
    if (this.orderCost === 'asc') {
      this.orderCost = 'des';
      tLi.classList.add('asc');
      this.hotels.sort(function (a, b) {
        if (a.low_cost > b.low_cost) {
          return 1;
        }
        if (a.low_cost < b.low_cost) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    } else {
      this.orderCost = 'asc';
      tLi.classList.add('des');
      this.hotels.sort(function (a, b) {
        if (a.low_cost < b.low_cost) {
          return 1;
        }
        if (a.low_cost > b.low_cost) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
  }

  removeArrows() {
    const liS = document.querySelectorAll('.bottom-filters li');
    for (let i = 0; i < liS.length; i++) {
      liS[i].classList.remove('asc');
      liS[i].classList.remove('des');
    }
  }

  onEditSearch() {
    this._router.navigate(['/hotel_search'], {queryParams: {redirect: 'hotels'}});
  }

  changeViewMode1(vm, i, j) {
    this.viewMode1 = vm;
    if (vm === 'subtab-2') {
      const hotelT = this.hotels[i];
      const offer = this.hotels[i].offers[j];
      if (!hotelT.details_data.description) {
        this.getHotelDetails(offer, i);
      }
    }
  }

  getHotelDetails(offer: OfferModel, i) {
    const params: RequestParams = {

      params: {
        id: this.hotels[i].id
      },
      search: {
        id_search_code: offer.hotel_search_code
      }
    };
    this._spinner.show();
    this._hotelService.getHotelDetails(params).subscribe(
      (data) => {
        this._spinner.hide();
        this.hotels[i].details_data = data.data;
      }, (error) => {
        this._spinner.hide();
        if (error.name && error.name == 'TimeoutError') {
          Swal.fire({
            title: 'A time out error ocurred',
            text: 'The provider is temporarily out of service. Repeat your request.',
            type: 'error',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: ' Repeat request'
          }).then((result) => {
            if (result.value) {
              this.getHotelDetails(offer, i);
            }
          });
        }
      }
    );
  }

  onGoRoute(route) {
    this._router.navigate([route]);
  }

  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        var reserv = this._hotelService.getReservation();
        reserv.automatic_refresh = true;
        this._hotelService.setReservation(reserv);
        this._router.navigate(['/hotel_search']);
      }
    });
    // A la fuerza
    var tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

}
