import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../services/login.service';
import {SignUserModel} from '../models/login.model';
import {GeneralUtil} from '../core/util/general.util';
import {UserService} from '../services/user.service';
import {RequestParams} from '../core/api/api.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserModel} from '../models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  signForm: FormGroup;
  redirectTo: string;
  userLogged: UserModel;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _loginService: LoginService,
    private _spinner: NgxSpinnerService,
    private _userService: UserService) {
  }


  ngOnInit() {
    this.redirectTo = this._route.snapshot.queryParams.redirect;
    if (!this.redirectTo) {
      this.redirectTo = 'home';
    }
    this.userLogged = this._loginService.getUserInfo();

    if (this.userLogged) {
      this._router.navigate(['/' + this.redirectTo]).then();
    }


    this.fillForm();
  }

  onSubmit() {
    if (this.signForm.valid) {
      const signUpUser = new SignUserModel();
      signUpUser.email = this.signForm.value.email;
      signUpUser.password = this.signForm.value.password;
      this._spinner.show().then();
      this._loginService.doLogin(signUpUser).subscribe(
        (data) => {
          this._spinner.hide().then();
          GeneralUtil.successMessage('Login Successful!');
          this.getLoggedUser();
        }, (err) => {
          this._spinner.hide().then();
          if (err.error.message) {
            GeneralUtil.errorMessage(err.error.message);
            return;
          }
          if (err.message) {
            GeneralUtil.errorMessage(err.message);
            return;
          }
        }
      );
    } else {
      GeneralUtil.validateAllFormFields(this.signForm);
    }
  }

  getLoggedUser() {
    this._loginService.getUserDetails().subscribe(
      (data) => {
        this._loginService.fireLoginChange(data.data);
        this._router.navigate(['/' + this.redirectTo]).then();
      });

  }

  fillForm() {
    this.signForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required)
    });
  }


}
