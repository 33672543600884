import {Component, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ChildAge, GenericModel, SearchModel} from '../../models';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../../services/common.service';
import {HotelService} from '../../services/hotel.service';
import {GeneralUtil} from '../../core/util/general.util';
import {ReservationModel} from '../../models/reservation.model';
import {IMyDateRangeModel, IMyDrpOptions} from 'mydaterangepicker';
import {NgxSpinnerService} from 'ngx-spinner';
import {RequestParams} from '../../core/api/api.model';
import Swal from 'sweetalert2';

declare function openWindow(event: any, wID: any);

@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.css']
})
export class HotelSearchComponent implements OnInit {

  d: Date = new Date();

  myDateRangePickerOptions: IMyDrpOptions = {
    // other options...
    width: '100%',
    height: '50px',
    dateFormat: 'dd/mm/yyyy',
    selectBeginDateTxt: 'Check In',
    selectEndDateTxt: 'Check Out',
    editableDateRangeField: false,
    openSelectorOnInputClick: true,
    indicateInvalidDateRange: true,
    disableUntil: {year: this.d.getFullYear(), month: this.d.getMonth() + 1, day: this.d.getDate()},


  };
  searchform: FormGroup;
  destinations: GenericModel[];
  subscriptionObject: Subscription;
  search: SearchModel;
  redirectTo: string;
  private model: any = {
    beginDate: {year: 2018, month: 10, day: 9},
    endDate: {year: 2018, month: 10, day: 19}
  };

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private _hotelService: HotelService,
    private __spinnerService: NgxSpinnerService) {
  }

  get itemsRoomPersons(): FormArray {
    return this.searchform.get('rooms_persons') as FormArray;
  }

  ngOnInit() {
    this.__spinnerService.hide();
    Swal.close();
    this.search = this._hotelService.getReservation() ? this._hotelService.getReservation().search : null;
    this.subscriptionObject = new Subscription();
    if (this.search) {
      this.fillFormUpdate();
    } else {
      this.fillForm();
    }
    this.destinations = this._hotelService.getReservation() ? (this._hotelService.getReservation().destinations ? this._hotelService.getReservation().destinations : []) : [];
    if (this.destinations.length == 0) {
      this.getDestinations();
    }
    this.listenSetChildAge();
    const automaticRefresh = this._hotelService.getReservation() ? this._hotelService.getReservation().automatic_refresh : false;
    if (automaticRefresh) {
      const reservation = new ReservationModel();
      reservation.automatic_refresh = false;
      this._hotelService.setReservation(reservation);
      this.getHotels(this.search);
    }
    this.redirectTo = this._route.snapshot.queryParams.redirect;

  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  listenSetChildAge() {
    this.subscriptionObject.add(this._hotelService._fireCloseSetChildsWindow.subscribe(
      (data: ChildAge) => {
        const items = this.searchform.get('rooms_persons') as FormArray;

        items.controls[data.room - 1].get('child').setValue(data.child_total);
        for (let k = 0; k < data.child_total; k++) {
          items.controls[data.room - 1].get('age' + (k + 1)).setValue(data.childrens[k].child_age);
        }
      }
    ));
  }

  getDestinations() {
    this.subscriptionObject.add(this._commonService.getDestinations().subscribe(
      (data) => {
        this.destinations = data.data;
      }, (error) => {
        this.getDestinations();
      }
    ));
  }

  onSubmit() {
    if (this.searchform.valid) {
      const search = new SearchModel();
      const model = Object.assign({}, search, this.searchform.value);
      model.check_in = model.check_in;
      model.check_out = model.check_out;
      model.destination = this.findDestinationById(model.destination_id);
      const reservation = new ReservationModel();
      reservation.search = model;
      reservation.destinations = this.destinations;
      this._hotelService.setReservation(reservation);
      this.getHotels(model);
      //this._router.navigate(['/hotels']);
    } else {
      GeneralUtil.validateAllFormFields(this.searchform);
    }
  }

  getHotels(searchT: SearchModel) {
    const filter = [];
    filter.push({field: 'destination_id', op: '=', data: searchT.destination_id});
    filter.push({field: 'check_in', op: '=', data: searchT.check_in});
    filter.push({field: 'check_out', op: '=', data: searchT.check_out});
    filter.push({field: 'rooms_number', op: '=', data: searchT.rooms_number});
    const rooms_persons = {};
    let adult = 0;
    let childrens = 0;
    for (let i = 0; i < searchT.rooms_persons.length; i++) {
      const ages = [];
      for (let k = 0; k < searchT.rooms_persons[i].child; k++) {
        ages.push(searchT.rooms_persons[i]['age' + (k + 1)]);
      }
      const room = {};
      rooms_persons[i + 1] = {
        adult: searchT.rooms_persons[i].adult,
        child: searchT.rooms_persons[i].child,
        ages: ages,
      };
      adult += searchT.rooms_persons[i].adult;
      childrens += searchT.rooms_persons[i].child;

    }

    searchT.adults = adult;
    searchT.childrens = childrens;
    const reserv = this._hotelService.getReservation();
    reserv.search = searchT;
    reserv.offer = null;
    reserv.hotel = null;
    this._hotelService.setReservation(reserv);

    filter.push({field: 'rooms_persons', op: '=', data: rooms_persons});

    const params: RequestParams = {
      filters: filter
    };
    this.__spinnerService.show();
    this.subscriptionObject.add(this._hotelService.getHotels(params).subscribe(
      (data) => {
        this.__spinnerService.hide();
        const hotels = data.data;
        if (hotels.length === 0) {
          // tslint:disable-next-line:max-line-length
          const msgTxt = '<h3 class="title text-uc text-c4 tac mt-1 mb-2">There are not available offers</h3>' + '<p class="mb-2">Change your search params and try again.</p>';
          GeneralUtil.infoMessage2(msgTxt, 5000);
        } else {
          reserv.list_hotels = hotels;
          reserv.actual_time = new Date().getTime();
          this._hotelService.setReservation(reserv);
          this._router.navigate(['/hotels']);
        }

      },
      (error) => {
        this.__spinnerService.hide();
        if (error.name && error.name === 'TimeoutError') {
          Swal.fire({
            html: '<h3 class="title text-uc text-c4 tac mt-1 mb-2">A time out error ocurred</h3>' +
              '<p class="mb-1">The provider is temporarily out of service.</p><p class="mb-2">Repeat your search or start a new one.</p>',
            type: 'error',
            showCancelButton: true,
            cancelButtonText: 'NEW SEARCH',
            confirmButtonText: 'REPEAT SEARCH',
            customClass: {
              confirmButton: 'btn btn-sm btn-3rd col-45-1 px-0',
              cancelButton: 'btn btn-sm btn-2nd col-45-1 px-0',
              actions: 'full-w'
            }
          }).then((result) => {
            if (result.value) {
              const searchAgain = this._hotelService.getReservation().search;
              this.getHotels(searchAgain);
            }
          });
        }
      }
    ));
  }

  fillForm() {
    const items: FormGroup[] = [];
    for (let i = 0; i < 1; i++) {
      items.push(new FormGroup({
        'room': new FormControl(i + 1, Validators.required),
        'adult': new FormControl(1, Validators.required),
        'child': new FormControl(0, Validators.required),
        'age1': new FormControl(0, Validators.required),
        'age2': new FormControl(0, Validators.required),
        'age3': new FormControl(0, Validators.required),
      }));
    }
    this.searchform = new FormGroup({
      'destination_id': new FormControl('', [Validators.required]),
      'check_in': new FormControl(null, [Validators.required]),
      'check_out': new FormControl(null, Validators.required),
      'myDateRange': new FormControl(null, Validators.required),
      'rooms_number': new FormControl(1, Validators.required),
      'rooms_persons': new FormArray(items),
    });
  }

  fillFormUpdate() {
    const items: FormGroup[] = [];
    for (let i = 0; i < this.search.rooms_persons.length; i++) {
      items.push(new FormGroup({
        'room': new FormControl(i + 1, Validators.required),
        'adult': new FormControl(this.search.rooms_persons[i].adult, Validators.required),
        'child': new FormControl(this.search.rooms_persons[i].child, Validators.required),
        'age1': new FormControl(this.search.rooms_persons[i].age1, Validators.required),
        'age2': new FormControl(this.search.rooms_persons[i].age2, Validators.required),
        'age3': new FormControl(this.search.rooms_persons[i].age3, Validators.required),
      }));
    }
    this.searchform = new FormGroup({
      'destination_id': new FormControl(this.search.destination_id, [Validators.required]),
      'check_in': new FormControl(this.search.check_in, [Validators.required]),
      'check_out': new FormControl(this.search.check_out, Validators.required),
      'myDateRange': new FormControl(this.search.myDateRange, Validators.required),
      'rooms_number': new FormControl(this.search.rooms_persons.length, Validators.required),
      'rooms_persons': new FormArray(items),
    });
  }


  onDateRangeChanged(event: IMyDateRangeModel) {
    const checkIn = this.resolveDate(event.beginDate);
    const checkOut = this.resolveDate(event.endDate);
    this.searchform.get('check_in').setValue(checkIn);
    this.searchform.get('check_out').setValue(checkOut);
    this.onCloseClose(0);
  }


  clearDateRange(): void {
    // Clear the date range using the patchValue function
    this.searchform.patchValue({myDateRange: ''});
  }

  findDestinationById(id) {
    let destSelect = null;
    this.destinations.map(
      (dest, index) => {
        if (dest.id == id) {
          destSelect = dest;
        }
      }
    );
    return destSelect;
  }

  resolveDate(date) {
    const day = date.day < 10 ? '0' + date.day : date.day;
    const month = date.month < 10 ? '0' + date.month : date.month;
    const year = date.year;
    return year + '-' + month + '-' + day;

  }

  // - - - - - - - - - - - - - - DATEPICKER CLOSE BUTTON
  onDatePicker0() {
    document.getElementById('top-menu').style.zIndex = '-1';
    const pt = document.getElementsByClassName('page-title')[0] as HTMLDivElement;
    pt.style.zIndex = '-1';
    document.getElementById('closeDP0').style.display = 'block';
  }

  /*
  onDatePicker1() {
    document.getElementById('closeDP1').style.display = 'block';
  }
  */
  onCloseClose(bID) {
    document.getElementById('top-menu').style.zIndex = '10';
    const pt = document.getElementsByClassName('page-title')[0] as HTMLDivElement;
    pt.style.zIndex = 'initial';
    document.getElementById('closeDP' + bID).style.display = 'none';
  }

  // - - - - - - - - - - - - - - / DATEPICKER CLOSE BUTTON

  onSelectACR() {
    alert('Tengo que hacer la ventana para esta selección, mientras tanto usar valores predeterminados. ;)');
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESTA()
  restaRoom(field, minVal) {
    let tVal = parseInt(this.searchform.get(field).value) - 1;
    tVal = tVal < minVal ? minVal : tVal;
    this.searchform.get(field).setValue(tVal);
    if (field == 'rooms_number') {
      const items = this.searchform.get('rooms_persons') as FormArray;
      if (items.controls.length > minVal) {
        items.removeAt(items.length - 1);
      }
    }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SUMA()
  sumaRoom(field, maxVal) {
    let tVal = parseInt(this.searchform.get(field).value) + 1;
    tVal = tVal > maxVal ? maxVal : tVal;
    this.searchform.get(field).setValue(tVal);
    if (field == 'rooms_number') {
      const items = this.searchform.get('rooms_persons') as FormArray;
      if (items.controls.length < maxVal) {
        items.push(this.createItem(tVal));
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESTA()
  resta(field, position, minVal) {
    const items = this.searchform.get('rooms_persons') as FormArray;
    let tVal = parseInt(items.controls[position].get(field).value) - 1;
    tVal = tVal < minVal ? minVal : tVal;
    items.controls[position].get(field).setValue(tVal);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SUMA()
  suma(field, position, maxVal) {
    const items = this.searchform.get('rooms_persons') as FormArray;
    let tVal = parseInt(items.controls[position].get(field).value) + 1;
    tVal = tVal > maxVal ? maxVal : tVal;
    items.controls[position].get(field).setValue(tVal);
  }

  createItem(tVal): FormGroup {
    return new FormGroup({
      'room': new FormControl(tVal, Validators.required),
      'adult': new FormControl(1, Validators.required),
      'child': new FormControl(0, Validators.required),
      'age1': new FormControl(0),
      'age2': new FormControl(0),
      'age3': new FormControl(0),
    });
  }

  calcOrdinals(i) {
    return GeneralUtil.calcOrdinals(i);

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ON INP CHILD ()
  onInpChild(event, wID, room) {
    const search = new SearchModel();
    const model = Object.assign({}, search, this.searchform.value);
    const roomPerson = model.rooms_persons[room];
    this._hotelService.onOpenSetChildsWindow(roomPerson);
    openWindow(event, wID);
  }

  getStringAges(j) {
    const items = this.searchform.get('rooms_persons') as FormArray;
    const room = items.controls[j];
    // items.controls[data.room - 1].get('age' + (k + 1)).setValue(data.childrens[k].child_age);
    let ageString = '';
    for (let k = 0; k < room.get('child').value; k++) {
      if (k == 0) {
        ageString += room.get('age' + (k + 1)).value;
      } else {
        ageString += ', ' + room.get('age' + (k + 1)).value;
      }
    }
    return ageString;
  }

  goToHotels() {
    this._router.navigate(['/hotels']);
  }

}
