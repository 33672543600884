import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-booking-confirmation-mail-en',
  templateUrl: './booking-confirmation-mail-en.component.html',
  styleUrls: ['./booking-confirmation-mail-en.component.css']
})
export class BookingConfirmationMailEnComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
