import {Component, OnInit} from '@angular/core';

// map


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

}
