import {Component, enableProdMode, OnInit} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';
import {PrintService} from './services/print-service.service';

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  title = 'app';

  constructor(public printService: PrintService, private _router: Router) {
  }

  ngOnInit() {
    this._router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.closeAllWindows();
      }
    });

  }

  closeAllWindows() {
    var w = document.getElementsByClassName('window');
    if (w.length > 0) {
      for (var i = 0; i < w.length; i++) {
        w[i].classList.remove('in');
      }
    }
    var cw = document.getElementById('window-cover');
    if (cw) {
      cw.classList.remove('in');
    }
  }

}
