import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-p-home-top-menu',
  templateUrl: './p-home-top-menu.component.html',
  styleUrls: ['./p-home-top-menu.component.css']
})
export class PHomeTopMenuComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  onMenuIn(event) {
    event.stopPropagation();
    document.getElementById('main-menu').classList.add('in');
  }

}
