import {Injectable} from '@angular/core';
import {ApiService} from '../core/api/api.service';
import {StoreUtil} from '../core/util/store.util';
import {NauticalReservationModel} from '../models/nautical.model';
import {apiroutes} from '../api-routing';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NauticalService {


  reservation: NauticalReservationModel = null;
  _fireNauticalOffer: Subject<any> = new Subject<any>();

  constructor(
    private _apiService: ApiService) {
  }

  fireNauticalOffer(offer) {
    this._fireNauticalOffer.next(offer);
  }

  setReservation(r: NauticalReservationModel) {
    StoreUtil.objectToStore('nautReservation', r);
    this.reservation = r;
  }

  getReservation() {
    this.reservation = StoreUtil.objectFromStore('nautReservation');
    return this.reservation;

  }

  resetReservation() {
    StoreUtil.remove('nautReservation');
    this.reservation = null;
  }

  makeAReservation(reserv: any) {
    return this._apiService.post(apiroutes.r_naut_send_confirmation, reserv);
  }
}
