import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.css']
})
export class LangSelectorComponent implements OnInit {

  lang = ['en', 'es', 'fr', 'he'];
  langAct = 0;
  vmLang = 'lang-0';

  constructor() {
  }

  ngOnInit() {
  }

  moveLang() {
    var totalLang = this.lang.length - 1;
    this.langAct = this.langAct > totalLang ? 0 : this.langAct;
    this.langAct = this.langAct < 0 ? totalLang : this.langAct;
    this.vmLang = 'lang-' + this.langAct;
    /*
    var lc = document.getElementById('lang-container').getElementsByClassName('lang-content')[0] as HTMLDivElement;
    lc.className = 'lang-content p'+this.langAct;
    var pos = '-' + this.langAct * 100;
    lc.style.left = pos+'%';
    */
  }

  onLangPrev(event) {
    event.stopPropagation();
    //
    this.langAct--;
    this.moveLang();
  }

  onLangNext(event) {
    event.stopPropagation();
    //
    this.langAct++;
    this.moveLang();
  }

}
