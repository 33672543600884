import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from '../login.service';
import {GeneralUtil} from '../../core/util/general.util';

@Injectable()
export class UserGuard implements CanActivate, CanActivateChild {
  constructor(
    private _loginService: LoginService,
    private _router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var token = this._loginService.getToken();
    if (token) {
      if (GeneralUtil.isTokenAlive(token)) {
        if (route.url[0].path == 'login') {
          this._router.navigate(['/home']);
        }
        return true;
      } else {
        this._loginService.getUserDetails().subscribe(
          (data) => {
          }
        );
      }

    } else {
      if (route.url[0].path == 'login' || route.url[0].path == 'register') {
        return true;
      }

      this._router.navigate(['/home']);
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
