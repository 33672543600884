import {GenericModel} from '.';

export class SearchModel {
  destination_id: number;
  check_in: string;
  check_out: string;
  myDateRange: any;
  rooms_number: number;
  rooms_persons: RoomPerson[];
  destination: GenericModel;
  adults: number;
  childrens: number;
}

export class RoomPerson {
  room: number;
  adult: number;
  child: number;
  age1: number;
  age2: number;
  age3: number;
  age4: number;
}


export class ChildAge {
  room: number;
  child_total: number;
  childrens: { child_age: number, child_number: number }[];
}



