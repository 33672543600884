import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HotelModel} from '../../../models';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-w-about-hotel-map',
  templateUrl: './w-about-hotel-map.component.html',
  styleUrls: ['./w-about-hotel-map.component.css']
})
export class WAboutHotelMapComponent implements OnInit, OnDestroy {

  @Input('hotelSelected') hotelSelected: HotelModel;
  /* @ViewChild('map') map: AgmMap; */
  /*   latitude: number;
  longitude: number; */
  mapUrl: string;

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    /*  this.latitude = this.hotelSelected.details_data.latitude);
     this.longitude = parseFloat(this.hotelSelected.details_data.longitude); */
    this.mapUrl = '';
    this.mapUrl = 'https://www.google.com/maps/embed/v1/place?q=';
    this.mapUrl += this.hotelSelected.details_data.latitude + ',' + this.hotelSelected.details_data.longitude;
    this.mapUrl += '&key=';
    this.mapUrl += 'AIzaSyDjL19EuV-7T1PQc9IV_a1M6Y2jFvkzMSE&maptype=satellite';
    /* setTimeout(() => {
      this.map.triggerResize(); }, 3000);
    this.map.triggerResize(); */
  }

  ngOnDestroy() {
    this.mapUrl = '';
  }

}
