import {Component, OnDestroy, OnInit} from '@angular/core';
import {HotelService} from '../../../services/hotel.service';
import {RoomPerson} from '../../../models';
import {Subscription} from 'rxjs';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {GeneralUtil} from '../../../core/util/general.util';

declare function closeWindow(event: any, wID: any);

@Component({
  selector: 'app-w-inp-child',
  templateUrl: './w-inp-child.component.html',
  styleUrls: ['./w-inp-child.component.css']
})
export class WInpChildComponent implements OnInit, OnDestroy {

  roomPerson: RoomPerson;
  childrensform: FormGroup;
  subscriptionObject: Subscription = new Subscription();
  show: boolean = false;

  constructor(private _hotelService: HotelService) {
  }

  get itemsChildrens(): FormArray {
    return this.childrensform.get('childrens') as FormArray;
  }

  ngOnInit() {
    this.listenOnOpenWindow();
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  listenOnOpenWindow() {
    this.subscriptionObject.add(this._hotelService._fireOpenSetChildsWindow.subscribe(
      (data) => {
        this.roomPerson = data;
        this.fillForm();
        this.show = true;
      }
    ));
  }

  fillForm() {
    var items: FormGroup[] = [];
    for (var i = 0; i < this.roomPerson.child; i++) {
      items.push(new FormGroup({
        'child_number': new FormControl(i + 1, Validators.required),
        'child_age': new FormControl(this.roomPerson['age' + (i + 1)] ? this.roomPerson['age' + (i + 1)] : 0, [Validators.required, Validators.min(0), Validators.max(18)]),
      }));
    }
    this.childrensform = new FormGroup({
      'child_total': new FormControl(this.roomPerson.child, [Validators.required]),
      'childrens': new FormArray(items),
    });
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESTA()
  resta(minVal) {
    let tVal = parseInt(this.childrensform.get('child_total').value) - 1;
    tVal = tVal < minVal ? minVal : tVal;
    this.childrensform.get('child_total').setValue(tVal);
    var items = this.childrensform.get('childrens') as FormArray;
    if (items.controls.length > minVal) {
      items.removeAt(items.length - 1);
    }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SUMA()
  suma(maxVal) {
    let tVal = parseInt(this.childrensform.get('child_total').value) + 1;
    tVal = tVal > maxVal ? maxVal : tVal;
    this.childrensform.get('child_total').setValue(tVal);
    var items = this.childrensform.get('childrens') as FormArray;
    if (items.controls.length < maxVal) {
      items.push(this.createItem(tVal));
    }
  }

  createItem(tVal): FormGroup {
    return new FormGroup({
      'child_number': new FormControl(tVal, Validators.required),
      'child_age': new FormControl(0, [Validators.required, Validators.min(0), Validators.max(18)]),
    });
  }

  calcOrdinals(i) {
    return GeneralUtil.calcOrdinals(i);
  }

  cancelW(event, wID) {
    event.stopPropagation();
    this.show = false;
    closeWindow(event, wID);
  }

  onSubmit(event) {
    if (this.childrensform.valid) {
      this.show = false;
      var items = this.childrensform.get('childrens') as FormArray;
      var obj = {
        room: this.roomPerson.room,
        child_total: this.childrensform.get('child_total').value,
        childrens: items.value
      };
      this._hotelService.onCloseSetChildsWindow(obj);
      closeWindow(event, 'w-inp-child');
    } else {
      GeneralUtil.validateAllFormFields(this.childrensform);
    }

  }

  closeW(event, wID) {
    event.stopPropagation();
    this.show = false;
    closeWindow(event, wID);
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESTA()
  restaAge(minVal, position) {
    var items = this.childrensform.get('childrens') as FormArray;
    let tVal = parseInt(items.controls[position].get('child_age').value) - 1;
    tVal = tVal < minVal ? minVal : tVal;
    items.controls[position].get('child_age').setValue(tVal);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SUMA()
  sumaAge(maxVal, position) {
    var items = this.childrensform.get('childrens') as FormArray;

    let tVal = parseInt(items.controls[position].get('child_age').value) + 1;
    tVal = tVal > maxVal ? maxVal : tVal;
    items.controls[position].get('child_age').setValue(tVal);
  }

}
