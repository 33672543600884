import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.css']
})
export class MyBookingsComponent implements OnInit {

  // viewModeB = [ hotel, apartment, ride, tour, park, food, night, bracelet ]
  viewModeB = 'hotel';

  constructor(
    private _router: Router
  ) {
  }

  ngOnInit() {
  }

  changeViewMode(el) {
    this.viewModeB = this.viewModeB == el ? '' : el;
    //this._router.navigate(['/my_bookings'], {fragment: el} );
    document.getElementById('A' + el).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    setTimeout(function () {
      var offTop = document.getElementById('A' + el).offsetTop;
      document.getElementById('main-container').scrollTop = offTop - 100;

    }, 250);
  }

}
