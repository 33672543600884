import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-confirm-hotel',
  templateUrl: './confirm-hotel.component.html',
  styleUrls: ['./confirm-hotel.component.css']
})
export class ConfirmHotelComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  onConfirm() {
    //
    this.router.navigate(['/hotel_booking_guest']);
  }

}
