import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './/app-routing.module';
import {SplashComponent} from './splash/splash.component';
import {P404Component} from './p404/p404.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {PMainMenuComponent} from './p-main-menu/p-main-menu.component';
import {PTopMenuComponent} from './p-top-menu/p-top-menu.component';
import {CalendarComponent} from './calendar/calendar.component';
import {PaymentComponent} from './payment/payment/payment.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxMyDatePickerModule} from 'ngx-mydatepicker';
import {LoginService} from './services/login.service';
import {ApiService} from './core/api/api.service';
import {RequestInterceptor} from './core/util/request.interceptor';
import {UserGuard} from './services/guards/user-guard.service';
import {CommonService} from './services/common.service';
import {UserService} from './services/user.service';
// HOTEL
import {HotelSearchComponent} from './hotel/hotel-search/hotel-search.component';
import {HotelsComponent} from './hotel/hotels/hotels.component';
import {BookHotelComponent} from './booking/book-hotel/book-hotel.component';
import {ConfirmHotelComponent} from './booking/confirm-hotel/confirm-hotel.component';
import {HotelPreviewComponent} from './calendar-preview/hotel-preview/hotel-preview.component';
import {BookHotelGuestComponent} from './booking/book-hotel-guest/book-hotel-guest.component';
import {BookHotelAddressComponent} from './booking/book-hotel-address/book-hotel-address.component';
import {HotelService} from './services/hotel.service';
import {WAboutHotelComponent} from './hotel/windows/w-about-hotel/w-about-hotel.component';
import {WAboutHotelMapComponent} from './hotel/windows/w-about-hotel-map/w-about-hotel-map.component';
import {WHotelAddGuestsComponent} from './booking/windows/w-hotel-add-guests/w-hotel-add-guests.component';
import {MbHotelComponent} from './my-bookings/mb-hotel/mb-hotel.component';
// TOURS
import {ToursSearchComponent} from './tour/tours-search/tours-search.component';
import {ToursComponent} from './tour/tours/tours.component';

import {AgmCoreModule} from '@agm/core';
import {NgxStripeModule} from '@nomadreservations/ngx-stripe';
import {PaymentApprovedComponent} from './payment/payment-approved/payment-approved.component';
import {WEventDetailComponent} from './calendar/w-event-detail/w-event-detail.component';
import {AccomodationDetailComponent} from './calendar/w-accomodation-detail/w-accomodation-detail.component';

import {CustomCurrencyPipe} from './core/pipes/custom-currency-pipe';
import {CustomAmountPipe} from './core/pipes/custom-amount-pipe';
import {CurrencyPipe} from '@angular/common';

import {MyBookingsComponent} from './my-bookings/my-bookings.component';
import {GalleryComponent} from './gallery/gallery.component';
import {MapComponent} from './map/map.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MyDateRangePickerModule} from 'mydaterangepicker';
import {HomeComponent} from './home/home.component';
import {PHomeTopMenuComponent} from './home/p-home-top-menu/p-home-top-menu.component';
import {HomeGalleryComponent} from './home/home-gallery/home-gallery.component';
import {WInpChildComponent} from './hotel/windows/w-inp-child/w-inp-child.component';

import {LimitToDirective} from './core/directives/limit-to.directive';
import {PUnderConstructionComponent} from './p-under-construction/p-under-construction.component';
import {WMapComponent} from './my-bookings/mb-hotel/w-map/w-map.component';
import {WUnderConstructionComponent} from './w-under-construction/w-under-construction.component';
import {SanitizerPipe} from './core/pipes/sanitizer.pipe';
import {LangSelectorComponent} from './lang-selector/lang-selector.component';
import {PrintService} from './services/print-service.service';
import {PrintComponentComponent} from './print-component/print-component.component';
import {BookingConfirmationEnComponent} from './html-docs/booking-confirmation-en/booking-confirmation-en.component';
import {RegisterMailEnComponent} from './html-docs/register-mail-en/register-mail-en.component';
import {BookingConfirmationMailEnComponent} from './html-docs/booking-confirmation-mail-en/booking-confirmation-mail-en.component';
import {NauticalComponent} from './nautica/nautica/nautical.component';
import {NauticaGalleryComponent} from './nautica/nautica-gallery/nautica-gallery.component';
import {PFooterComponent} from './p-footer/p-footer.component';
import {BookNauticalComponent} from './booking/book-nautica/book-nautical.component';
import {NauticalBookingDetailsComponent} from './booking/nautica-booking-details/nautical-booking-details.component';
import {NauticalBookingGuestComponent} from './booking/nautica-booking-guest/nautical-booking-guest.component';
import {LettersOnlyDirective} from './core/directives/letters-only.directive';
import {LetterSpacesOnlyDirective} from './core/directives/letter-spaces-only.directive';
import {BillingAddressDirective} from './core/directives/billing-address.directive';
import {NumbersOnlyDirective} from './core/directives/numbers-only.directive';
import {AlphanumericOnlyDirective} from './core/directives/alphanumeric-only.directive';
import {SecurityDirective} from './core/directives/security.directive';
import {OnlyNumbersDirective} from './core/directives/only-numbers.directive';
import {AlphanumericSpacesOnlyDirective} from './core/directives/alphanumeric-spaces-only.directive';
import { TourNauticalComponent } from './nautica/tour-nautica/tour-nautical.component';


@NgModule({
  declarations: [
    LimitToDirective,
    AppComponent,
    SanitizerPipe,
    SplashComponent,
    P404Component,
    LoginComponent,
    RegisterComponent,
    PMainMenuComponent,
    PTopMenuComponent,

    // HOTEL
    HotelSearchComponent,
    HotelsComponent,
    BookHotelComponent,
    ConfirmHotelComponent,
    HotelPreviewComponent,
    BookHotelGuestComponent,
    BookHotelAddressComponent,
    WAboutHotelComponent,
    WAboutHotelMapComponent,
    WHotelAddGuestsComponent,
    MbHotelComponent,

    // TOURS
    ToursSearchComponent,
    ToursComponent,

    CalendarComponent,
    PaymentComponent,
    PaymentApprovedComponent,
    WEventDetailComponent,
    AccomodationDetailComponent,
    CustomCurrencyPipe,
    CustomAmountPipe,
    MyBookingsComponent,
    GalleryComponent,
    MapComponent,
    HomeComponent,
    PHomeTopMenuComponent,
    HomeGalleryComponent,
    WInpChildComponent,
    PUnderConstructionComponent,
    WMapComponent,
    WUnderConstructionComponent,
    LangSelectorComponent,
    PrintComponentComponent,
    BookingConfirmationEnComponent,
    RegisterMailEnComponent,
    BookingConfirmationMailEnComponent,
    NauticalComponent,
    NauticaGalleryComponent,
    PFooterComponent,
    BookNauticalComponent,
    NauticalBookingDetailsComponent,
    NauticalBookingGuestComponent,
    LettersOnlyDirective,
    NumbersOnlyDirective,
    AlphanumericOnlyDirective,
    LetterSpacesOnlyDirective,
    AlphanumericSpacesOnlyDirective,
    BillingAddressDirective,
    SecurityDirective,
    OnlyNumbersDirective,
    TourNauticalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgxMyDatePickerModule.forRoot(),
    FormsModule,
    MyDateRangePickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCYNZ39O9jrrIrYzCc4xI3fe7tIODWK4Kc'
    }),
    NgxStripeModule.forRoot('pk_test_Xo84EKGoLWk8wBTrL82qJAVl'),

  ],
  providers: [
    LoginService,
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    UserGuard,
    CommonService,
    UserService,
    HotelService,
    CurrencyPipe,
    PrintService

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
