import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';

export const INTEGER_VALIDATOR: ValidatorFn = Validators.pattern(/^[0-9]+$/);
export const DECIMAL_VALIDATOR: ValidatorFn = Validators.pattern(/^[0-9]+(.[0-9]+)?$/);
export const USERNAME_VALIDATOR: ValidatorFn = Validators.pattern(/^[a-zA-Z][\._a-zA-Z0-9]*$/);
export const TEXT_VALIDATOR: ValidatorFn = Validators.pattern(/^[\ a-zA-Z]+$/);
export const ALPHANUMERIC_VALIDATOR: ValidatorFn = Validators.pattern(/^[\ a-zA-Z0-9]+$/);
export const TEL_VALIDATOR: ValidatorFn = Validators.pattern(/^[0-9]{1,3}-[0-9]{3}-[0-9]{1,7}$/);

export const REPEATED_IDX_VALUE_VALIDATOR = (elements: any[], fn: Function) => {
  return (control: AbstractControl): { [key: string]: any } => {
    const val = control.value;

    return elements.some(e => fn(e, val)) ? {['forbiddenValue']: {val}} : null;
  };
};

export const MATCH_FIELD_VALIDATOR = (formResolver, field) => {
  return (control: AbstractControl): { [key: string]: any } => {
    const fromGroup = formResolver();

    if (!fromGroup) {
      return;
    }

    const fieldValue = fromGroup.get(field).value;

    if (fieldValue === control.value) {
      return null;
    }

    return {fieldNoMatch: control.value};
  };
};

export const REPEATED_VALUE_VALIDATOR = (formResolver, field) => {
  return (control: AbstractControl): { [key: string]: any } => {
    const fromGroup = formResolver();

    if (!fromGroup) {
      return;
    }

    const fieldValue = fromGroup.get(field).value;

    if (fieldValue === '') {
      return;
    }

    if (fieldValue !== control.value) {
      return null;
    }

    return {repeatedValue: control.value};
  };
};

export const CONFIRM_VALIDATOR = (form, that, ref, msg = 'fieldNoMatch') => (
  control: AbstractControl
): { [key: string]: any } => {
  if (!form) {
    return;
  }

  const value = form.get(ref).value;

  if (value === that.value) {
    return null;
  }

  return {msg: that.value};
};
