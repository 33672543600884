import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {HotelService} from '../../services/hotel.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Element, ElementOptions, ElementsOptions, StripeService} from '@nomadreservations/ngx-stripe';
import {GeneralUtil} from '../../core/util/general.util';
import {CommonService} from '../../services/common.service';
import {CountryModel, HotelModel, OfferModel, ReservationModel, SearchModel, UserInfoModel, UserModel} from '../../models';
import {Subscription} from 'rxjs';
import {LoginService} from '../../services/login.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {UserService} from '../../services/user.service';
import {StoreUtil} from '../../core/util/store.util';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy {

  viewMode1 = 'no_registrado';


  subscriptionObject: Subscription;
  subscriptionLogin: Subscription;
  subscriptionClient: Subscription;

  stripeKey = '';
  error: any;
  complete = false;
  element: Element;
  countries: CountryModel[];
  formSelected: number = 1;
  hotel: HotelModel;
  @ViewChild('cardHolder') cardHolder: ElementRef;
  @ViewChild('checkedUser') checkedComponent: ElementRef;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#529bc9',
        color: '#283d4a',
        lineHeight: '50px',
        fontWeight: 400,
        fontFamily: '"Source Sans Pro","Helvetica Neue", Helvetica, sans-serif',
        fontSize: '22px',
        '::placeholder': {
          color: '#aaaaaa'
        }
      },
      invalid: {
        color: '#d94230',
        iconColor: '#fa755a'
      }
    }
  };


  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;

  disableInput: boolean = false;

  userLogged: UserModel;
  reservation: ReservationModel;
  search: SearchModel;
  bookInfo: UserModel;
  offer: OfferModel;

  constructor(private _router: Router,
              private _hotelService: HotelService,
              private _stripeService: StripeService,
              private _commonService: CommonService,
              private _loginService: LoginService,
              private _userService: UserService,
              private _spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();
    this.subscriptionLogin = new Subscription();
    this.subscriptionClient = new Subscription();
    this.userLogged = this._loginService.getUserInfo();

    this.checkingData();
    this.listenLogin();

    this.getCountries();

    if (this.userLogged && this.userLogged.user_info) {
      this.formSelected = 2;
      this.disableInput = true;
      this.fillForm(this.userLogged);
    } else {
      this.formSelected = 1;
      this.disableInput = false;
      if (this.bookInfo) {
        this.fillForm(this.bookInfo);
      } else {
        this.fillForm(null);
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }

  listenLogin() {
    this.subscriptionLogin.add(this._loginService._fireOLoginChange.subscribe(
      (data) => {
        switch (data) {
          case null:
          case undefined:
            this.userLogged = null;
            this.onSlider(1);
            break;
          default:
            this.userLogged = data;
            this.onSlider(2);
            break;
        }
      })
    );
  }

  checkingData() {
    this.reservation = this._hotelService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        if (!GeneralUtil.isListHotelsAlive()) {
          this.searchExpireMessage();
          this._router.navigate(['/hotel.search']).then();
          return;
        }
        break;
    }

    this.search = this.reservation.search;

    switch (this.search) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.hotel = this.reservation.hotel;

    switch (this.hotel) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.offer = this.reservation.offer;

    switch (this.offer) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    this.bookInfo = this.reservation.book_info;

    switch (this.bookInfo) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        break;
    }

    const guests = this.reservation.guests;

    switch (guests) {
      case null:
      case undefined:
        this._hotelService.resetReservation();
        this._router.navigate(['/hotel.search']).then();
        break;
      default:
        if (guests.length < 1) {
          this._hotelService.resetReservation();
          this._router.navigate(['/hotel.search']).then();
        }
        break;
    }
  }

  cardUpdated(result) {
    this.element = result.element;
    this.complete = result.card.complete;
    this.error = undefined;
  }

  disableInputs() {
    this.stripeTest.get('address_line1').disable();
    this.stripeTest.get('address_line2').disable();
    this.stripeTest.get('address_ext_number').disable();
    this.stripeTest.get('address_int_number').disable();
    this.stripeTest.get('country_id').disable();
    this.stripeTest.get('address_state').disable();
    this.stripeTest.get('address_city').disable();
    this.stripeTest.get('address_zip').disable();
  }

  enableInputs() {
    this.stripeTest.get('address_line1').enable();
    this.stripeTest.get('address_line2').enable();
    this.stripeTest.get('address_ext_number').enable();
    this.stripeTest.get('address_int_number').enable();
    this.stripeTest.get('country_id').enable();
    this.stripeTest.get('address_state').enable();
    this.stripeTest.get('address_city').enable();
    this.stripeTest.get('address_zip').enable();
  }

  onSlider(id) {
    this.formSelected = id;
    if (this.formSelected === 1) {
      this.disableInput = false;
      this.enableInputs();
      this.fillForm(null);
    } else {
      if (this.userLogged) {
        if (this.userLogged.user_info) {
          this.disableInput = true;
          this.disableInputs();
          this.fillForm(this.userLogged);
          return;
        } else {
          this.onSlider(1);
          this.checkedComponent.nativeElement.click();
          GeneralUtil.infoMessage2('This user have no registered address!');
          return;
        }
      } else {
        this.goLoginOrRegister();
      }
    }
  }

  // noinspection JSMethodCanBeStatic
  zipCodeError(country = '', length = 0, format = '', generic = false) {
    let message;
    if (!generic) {
      message = country + ' needs a zip-code of ' + length + ' with a format of: ' + format;
    } else {
      message = 'Zip-Code must have a length 6 characters';
    }
    Swal.fire({
      title: 'Error!',
      text: message,
      type: 'error',
      confirmButtonText: 'Ok'
    }).then();
  }

  buy() {
    if (this.stripeTest.valid) {
      const selectedCountryID = parseInt(this.stripeTest.get('country_id').value, 10);
      const selectedZipCode = this.stripeTest.get('address_zip').value;
      const verifiedCountries = [200, 36, 122, 91];

      if (selectedCountryID === 200) {
        if (!(selectedZipCode.match(/^[0-9]{5}$/g))) {
          this.zipCodeError('United States', 5, '00000');
          return;
        }
      }

      if (selectedCountryID === 91) {
        if (!(selectedZipCode.match(/^[0-9]{5}$/g))) {
          this.zipCodeError('Israel', 5, '00000');
          return;
        }
      }

      if (selectedCountryID === 122) {
        if (!(selectedZipCode.match(/^[0-9]{5}$/g))) {
          this.zipCodeError('Mexico', 5, '00000');
          return;
        }
      }

      if (selectedCountryID === 36) {
        if (!(selectedZipCode.match(/^[a-zA-Z][0-9][a-zA-Z]\s[0-9][a-zA-Z][0-9]$/g))) {
          this.zipCodeError('Canada', 6, 'A1A 1A1');
          return;
        }
      }

      if (verifiedCountries.includes(selectedCountryID) === false) {
        if (!(selectedZipCode.match(/^[a-zA-Z0-9]{6}$/g))) {
          this.zipCodeError('', 0, '0', true);
          return;
        }
      }

      this._spinner.show().then();
      this._stripeService.createToken(this.element, {
        name: this.stripeTest.get('name').value,
        address_line1: this.stripeTest.get('address_line1').value,
        address_city: this.stripeTest.get('address_city').value,
        address_state: this.stripeTest.get('address_state').value,
        address_zip: this.stripeTest.get('address_zip').value,
        address_country: this.findCountryById(this.stripeTest.get('country_id').value).iso_code,
      }).subscribe(result => {
        const reservation = this._hotelService.getReservation();

        const reservation_rooms = [];
        const totalPrice = 0;


        const user_info = {
          address1: this.stripeTest.get('address_line1').value,
          address2: this.stripeTest.get('address_line2').value,
          city: this.stripeTest.get('address_city').value,
          state: this.stripeTest.get('address_state').value,
          zip_code: this.stripeTest.get('address_zip').value,
          country_id: this.stripeTest.get('country_id').value,
          int_number: this.stripeTest.get('address_int_number').value,
          ext_number: this.stripeTest.get('address_ext_number').value,
        };

        // const rooms_persons = [];
        //
        // for (const room of this.reservation.search.rooms_persons) {
        //   rooms_persons.push({adults: room.adult, childrens: room.child, ages: room.ages});
        // }

        const rooms_persons = [];
        let adult = 0;
        let childrens = 0;
        for (let i = 0; i < this.reservation.search.rooms_persons.length; i++) {
          const ages = [];
          for (let k = 0; k < this.reservation.search.rooms_persons[i].child; k++) {
            ages.push(this.reservation.search.rooms_persons[i]['age' + (k + 1)]);
          }
          const room = {};
          rooms_persons.push({
            adults: this.reservation.search.rooms_persons[i].adult,
            childrens: this.reservation.search.rooms_persons[i].child,
            ages: ages,
          });
          adult += this.reservation.search.rooms_persons[i].adult;
          childrens += this.reservation.search.rooms_persons[i].child;

        }

        const arrPhone = this.reservation.book_info.phone_number.split('-');

        let reservationRequest = null;

        if (this.reservation.hotel.provider.id === 1) {
          reservationRequest = {
            provider_id: this.reservation.hotel.provider.id,
            base_price: this.reservation.offer.base_price,

            token: result.token.id,
            last_four: result.token.card.last4,
            brand: result.token.card.brand,
            user_info: user_info,
            client: {
              first_name: this.reservation.book_info.name,
              last_name: this.reservation.book_info.last_name,
              email: this.reservation.book_info.email,
              user_id: this.reservation.book_info.id,
              country: arrPhone[0],
              area: arrPhone[1],
              number: arrPhone[2]
            },

            ggt_id: this.reservation.hotel.hotel_code,
            hotel_search_code: this.reservation.offer.hotel_search_code,
            nights: this.getNights(this.reservation.search),
            check_in: this.reservation.search.check_in,
            check_out: this.reservation.search.check_out,
            currency_id: this.reservation.offer.currency,
            city_id: this.reservation.search.destination_id,
            reservation_rooms: this.reservation.guests,
            rooms_persons: JSON.stringify(rooms_persons),
            total_price: this.reservation.offer.total_price,
            cancel_dead_line: this.reservation.offer.cxl_deadline
          };
        } else {
          const doAccommodation = [];

          for (let i = 0; i < this.reservation.guests.length; i++) {
            // noinspection SpellCheckingInspection
            doAccommodation.push(
              {
                'currency_id': this.reservation.offer.currency,
                'base_price': this.reservation.offer.base_price,
                'total_price': this.reservation.offer.total_price,
                'rate_key': this.reservation.offer.rate_key,
                'adults': this.reservation.guests[i].adults,
                'childrens': this.reservation.guests[i].childrens,
                'room_type': this.reservation.guests[i].room_type
              }
            );
          }

          // const cancelDate = this.reservation.offer.cxl_deadline.split('-');
          //
          // const cancelDateFormatted = cancelDate[2] + '-' + cancelDate[1] + '-' + cancelDate[0];

          reservationRequest = {
            provider_id: this.reservation.hotel.provider.id,
            currency_id: 'USD',
            base_price: this.reservation.offer.base_price,
            total_price: this.reservation.offer.total_price,

            hdo_id: this.reservation.hotel.hotel_code,
            room_type: this.reservation.offer.room_type,
            meal_plan: this.reservation.offer.meal_plan,
            market_id: this.reservation.offer.market_id,
            contract_id: this.reservation.offer.contract_id,
            interface_info: this.reservation.hotel.interface_info,
            check_in: this.reservation.search.check_in,
            check_out: this.reservation.search.check_out,

            reservation_rooms: doAccommodation,
            rooms_persons: JSON.stringify(rooms_persons),

            token: result.token.id,
            last_four: result.token.card.last4,
            brand: result.token.card.brand,
            user_info: user_info,
            client: {
              first_name: this.reservation.book_info.name,
              last_name: this.reservation.book_info.last_name,
              email: this.reservation.book_info.email,
              user_id: this.reservation.book_info.id,
              country: arrPhone[0],
              area: arrPhone[1],
              number: arrPhone[2]
            },
            hotel_search_code: null,
            nights: this.getNights(this.reservation.search),
            city_id: this.reservation.search.destination_id,
            cancel_dead_line: this.reservation.offer.cxl_deadline
          };
        }

        const userGuest = {
          email: this.reservation.book_info.email,
          last_name: this.reservation.book_info.last_name,
          name: this.reservation.book_info.name,
          phone_number: this.reservation.book_info.phone_number,
          user_info: user_info
        };

        if (this.userLogged) {
          this.userLogged.user_info = new UserInfoModel();
          this.userLogged.user_info.address1 = user_info.address1;
          this.userLogged.user_info.address2 = user_info.address2;
          this.userLogged.user_info.ext_number = user_info.ext_number;
          this.userLogged.user_info.int_number = user_info.int_number;
          this.userLogged.user_info.country_id = user_info.country_id;
          this.userLogged.user_info.zip_code = user_info.zip_code;
          this.userLogged.user_info.state = user_info.state;
          this.userLogged.user_info.city = user_info.city;
          StoreUtil.objectToStore('user', this.userLogged);
        }

        this.subscriptionClient.add(this._userService.registerGuest(userGuest).subscribe(
          () => {
            this._hotelService.makeAReservation(reservationRequest).subscribe(
              (data) => {
                this._spinner.hide().then();
                HotelService.setReservationResult(data.object);
                this._hotelService.resetReservation();
                this._router.navigate(['/payment_approved']).then();
              }, (error) => {
                this._spinner.hide().then();
                if (error.error.message) {
                  GeneralUtil.errorMessage(error.error.message);
                  return;
                }
                if (error.message) {
                  GeneralUtil.errorMessage(error.message);
                  return;
                }
              }
            );
          }, (error) => {
            this._spinner.hide().then();
            if (error.error.message) {
              GeneralUtil.errorMessage(error.error.message);
              return;
            }
            if (error.message) {
              GeneralUtil.errorMessage(error.message);
              return;
            }
          }
        ));
      }, (err) => {
        this._spinner.hide().then();
        switch (err.type) {
          case 'StripeCardError':
            // A declined card error
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeInvalidRequestError':
            // Invalid parameters were supplied to Stripe's API
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeAPIError':
            // An error occurred internally with Stripe's API
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeConnectionError':
            // Some kind of error occurred during the HTTPS communication
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeAuthenticationError':
            // You probably used an incorrect API key
            GeneralUtil.errorMessage(err.message);
            break;
          case 'StripeRateLimitError':
            // Too many requests hit the API too quickly
            GeneralUtil.errorMessage(err.message);
            break;
        }
      });
    } else {
      GeneralUtil.validateAllFormFields(this.stripeTest);
    }
  }


  getNights(search) {
    const date_1: any = new Date(search.check_in);
    const date_2: any = new Date(search.check_out);

    const day_as_milliseconds = 86400000;
    const diff_in_millisenconds = date_2 - date_1;
    const diff_in_days = diff_in_millisenconds / day_as_milliseconds;

    return diff_in_days;
  }

  findCountryById(id) {
    let country = null;
    this.countries.map(
      (c, index) => {
        if (c.id == id) {
          country = c;
        }
      }
    );
    return country;
  }

  fillForm(user) {
    if (this.formSelected === 1) {
      user = null;
    }
    let cardOlderName = '';
    if (this.cardHolder) {
      cardOlderName = this.cardHolder.nativeElement.value;
    }

    this.stripeTest = new FormGroup({
      'name': new FormControl(cardOlderName, [Validators.required]),
      'address_line1': new FormControl(user ? (user.user_info ? user.user_info.address1 : null) : null, [Validators.required]),
      'address_line2': new FormControl(user ? (user.user_info ? user.user_info.address2 : null) : null),
      'address_city': new FormControl(user ? (user.user_info ? user.user_info.city : null) : null, [Validators.required]),
      'address_state': new FormControl(user ? (user.user_info ? user.user_info.state : null) : null, [Validators.required]),
      'address_zip': new FormControl(user ? (user.user_info ? user.user_info.zip_code : null) : null, [Validators.required]),
      'country_id': new FormControl(user ? (user.user_info ? user.user_info.country_id : '') : '', [Validators.required]),
      'address_int_number': new FormControl(user ? (user.user_info ? user.user_info.int_number : null) : null),
      'address_ext_number': new FormControl(user ? (user.user_info ? user.user_info.ext_number : null) : null),
    });

    if (this.disableInput === true) {
      this.disableInputs();
    }
  }

  onPay() {
    //
    this._router.navigate(['/payment_approved']).then();
  }

  getCountries() {
    this.subscriptionObject.add(this._commonService.getCountries().subscribe(
      (data) => {
        this.countries = data.data;
      }
    ));
  }

  goLoginOrRegister() {
    Swal.fire({
      // title: 'Login or Register',
      confirmButtonText: 'CANCEL',
      html: '<h3 class="title text-c4 tac text-uc mb-2">Please</h3>' +
        '<button id="login" class="btn btn-nor btn-2nd full-w iblock">' +
        'LOG IN</button>' +
        '<h3 class="title text-c4 tac text-uc py-2">or</h3>' +
        '<div class="rounded bg-c4 p-2">' +
        '<button id="register" class="btn btn-nor btn-3rd full-w iblock">REGISTER</button>' +
        '<p class="font-round mt-2 text-w">Most high-end experience<br>at the most affordable price.</p>' +
        '</div>',
      customClass: {
        confirmButton: 'btn btn-sm btn-5th full-w'
      },
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const login = $('#login');
        const register = $('#register');

        login.addEventListener('click', () => {
          this._router.navigate(['/login'], {queryParams: {redirect: 'payment'}}).then();
          Swal.close();
        });

        register.addEventListener('click', () => {
          this._router.navigate(['/register'], {queryParams: {redirect: 'payment'}}).then();
          Swal.close();
        });

      },
      onClose: () => {
        this.checkedComponent.nativeElement.click();
      }
    }).then();
  }

  searchExpireMessage() {
    Swal.fire({
      title: 'Expired search',
      text: 'Your search has an expiration time of 20 min, these have already passed. Try again',
      type: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'New Search'
    }).then((result) => {
      if (result.value) {
        const reservation = this._hotelService.getReservation();
        reservation.automatic_refresh = true;
        this._hotelService.setReservation(reservation);
        this._router.navigate(['/hotel_search']).then();
      }
    });
    // A la fuerza
    const tBody = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    tBody.classList.remove('swal2-height-auto');
  }

}
