import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-p-under-construction',
  templateUrl: './p-under-construction.component.html',
  styleUrls: ['./p-under-construction.component.css']
})
export class PUnderConstructionComponent implements OnInit {

  constructor(
    private _location: Location
  ) {
  }

  ngOnInit() {
  }

  onGoBack() {
    this._location.back();
  }

}
