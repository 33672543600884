import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-register-mail-en',
  templateUrl: './register-mail-en.component.html',
  styleUrls: ['./register-mail-en.component.css']
})
export class RegisterMailEnComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    //
    //document.getElementsByTagName('body')[0].style.overflow="visible";
  }

}
