import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {LoginService} from '../../services/login.service';
import {HotelService} from '../../services/hotel.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NauticalService} from '../../services/nautical.service';
import {NauticalModel, NauticalReservationModel} from '../../models/nautical.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {INgxMyDpOptions} from 'ngx-mydatepicker';
import {GeneralUtil} from '../../core/util/general.util';

// noinspection SpellCheckingInspection
declare function restaTime(event: any, am: any, pm: any);
// noinspection SpellCheckingInspection
declare function sumaTime(event: any, am: any, pm: any);

@Component({
  selector: 'app-nautical-booking-details',
  templateUrl: './nautical-booking-details.component.html',
  styleUrls: ['./nautical-booking-details.component.css']
})
export class NauticalBookingDetailsComponent implements OnInit {

  subscriptionObject: Subscription;

  reservation: NauticalReservationModel;

  nautical_package: NauticalModel;

  guestForm: FormGroup;

  myOptionsCheckOut: INgxMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'su',
    markCurrentDay: true,
    closeSelectorOnDocumentClick: true,
    disableHeaderButtons: true
  };

  calendarsFlag = true;

  constructor(
    private _commonService: CommonService,
    private _loginService: LoginService,
    private _hotelService: HotelService,
    private _router: Router,
    private _userService: UserService,
    private _spinner: NgxSpinnerService,
    private _nautService: NauticalService) {
  }

  ngOnInit() {
    this.subscriptionObject = new Subscription();

    this.checkingData();
    this.setInitialDate();
    this.fillForm();
  }

  checkingData() {
    this.reservation = this._nautService.getReservation();

    switch (this.reservation) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        // noinspection SpellCheckingInspection
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }

    switch (this.reservation.user_id) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        // noinspection SpellCheckingInspection
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }

    this.nautical_package = this.reservation.nautical_package;

    switch (this.nautical_package) {
      case null:
      case undefined:
        this._nautService.resetReservation();
        // noinspection SpellCheckingInspection
        this._router.navigate(['/nautica']).then();
        break;
      default:
        break;
    }
  }

  onCheckOutChanged(event) {
    const date: Date = new Date(event.date.year + '-' + event.date.month + '-' + event.date.day);
    this.calendarsFlag = false;
    // this.calcNights(date);
  }

  setInitialDate() {
    const today = new Date();
    this.myOptionsCheckOut.disableUntil = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
  }

  onClickCalendar(event, dpRef) {
    if (this.calendarsFlag) {
      dpRef.toggleCalendar();
    } else {
      this.calendarsFlag = true;
    }
  }

  fillForm() {
    let totalGuests;
    if (this.nautical_package.totalPeople === 4) {
      totalGuests = '2 to 4';
    } else {
      totalGuests = '2 to 6';
    }
    this.guestForm = new FormGroup({
      'guests': new FormControl(totalGuests, [Validators.required]),
      'date': new FormControl(null, [Validators.required]),
      'DHour': new FormControl(12, [Validators.required]),
      'DMinute': new FormControl('00', [Validators.required]),
      'DPeriod': new FormControl('PM', [Validators.required]),
      'harbour': new FormControl('Puerto Cancun', [Validators.required]),
      'hotel': new FormControl(null),
      'address': new FormControl(null),
      'extra': new FormControl(null),
    });
  }

  findInvalidControls() {
    const controls = this.guestForm.controls;
    for (const nameC in controls) {
      if (controls[nameC].invalid) {
      }
    }
  }

  resolveAfterXSeconds(x, time) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, time);
    });
  }

  onSubmit() {
    if (this.guestForm.valid) {

      const guestForm = this.guestForm.value;

      const body = {
        name_paq_nautica: this.reservation.nautical_package.package_name,
        user_id: this.reservation.user_id,
        no_guest: guestForm.guests,
        departure_date: guestForm.date.formatted,
        departure_time: guestForm.DHour + ':' + guestForm.DMinute + ' ' + guestForm.DPeriod,
        departure_harbour: guestForm.harbour,
        transportation_hotel: guestForm.hotel,
        includes: this.reservation.nautical_package.features
      };

      this._spinner.show().then();
      this.subscriptionObject.add(this._nautService.makeAReservation(body).subscribe(
        (data) => {
          this._spinner.hide().then();
          GeneralUtil.successMessage(data.message);
          this.resolveAfterXSeconds(20, 2500).then(
            () => {
              this._nautService.resetReservation();
              this._router.navigate(['/home']).then();
            }
          );
        }, (error) => {
          GeneralUtil.errorMessage(error.error.message);
          this._spinner.hide().then();
        })
      );

    } else {
      this.findInvalidControls();
      GeneralUtil.validateAllFormFields(this.guestForm);
    }
  }


  // - - - - - - - - DEPARTURE TIME *
  Mer = 'PM';

  setMer(eI) {
    const eInp = eI.closest('.input-group').getElementsByTagName('input')[0] as HTMLInputElement;
    // tslint:disable-next-line:radix
    const eHour = parseInt(eInp.value);
    if ( eHour > 7 && eHour < 12 ) {
      this.Mer = 'AM';
    } else {
    this.Mer = 'PM';
  }
    const setMer = document.getElementById('bnMer') as HTMLInputElement;
    setMer.value = this.Mer;
    this.guestForm.get('DHour').patchValue(eHour);
    this.guestForm.get('DPeriod').patchValue(this.Mer);
  }

  dwnHour(event, am, pm) {
    restaTime(event, am, pm);
    const eI = event.target as HTMLButtonElement;
    this.setMer(eI);
  }
  upHour(event, am, pm) {
    sumaTime(event, am, pm);
    const eI = event.target as HTMLButtonElement;
    this.setMer(eI);
  }

  subMinutes(event) {
    const eI = event.target as HTMLButtonElement;
    const eInp = eI.closest('.input-group').getElementsByTagName('input')[0] as HTMLInputElement;
    // tslint:disable-next-line:radix
    let vMin = parseInt(eInp.value) - 15;
    vMin = vMin < 0 ? 45 : vMin;
    if ( vMin === 0 ) {
      eInp.value =  '00';
    } else {
      eInp.value =  vMin.toString();
    }
    this.guestForm.get('DMinute').patchValue(eInp.value);
  }

  sumMinutes(event) {
    const eI = event.target as HTMLButtonElement;
    const eInp = eI.closest('.input-group').getElementsByTagName('input')[0] as HTMLInputElement;
    // tslint:disable-next-line:radix
    let vMin = parseInt(eInp.value) + 15;
    vMin = vMin > 45 ? 0 : vMin;
    if ( vMin === 0 ) {
      eInp.value =  '00';
    } else {
      eInp.value =  vMin.toString();
    }
    this.guestForm.get('DMinute').patchValue(eInp.value);
  }

  // - - - - - - - - / DEPARTURE TIME *

}
