export class HotelModel {
  id: number;
  hotel_code: string;
  hotel_name: string;
  country_id: number;
  stars: number;
  location: string;
  location_code: string;
  thumbnail: string;
  low_cost: number;
  low_cost_currency: string;
  offers: OfferModel[];
  details_data: HotelDetailModel;
  provider_id: number = 1;
  provider: ProviderModel;
  interface_info: string;

  // Old
  latitude: string;
  longitude: string;
  address: string;
  phone: string;
  city: string;
  fax: string;

}

export class ProviderModel {
  name: string;
  id: number;
}

export class HotelInfoModel {
  id: number;
  hotel_code: number;
  hotel_name: string;
  country_id: number;
  provider: ProviderModel;
  stars: number;
  location: string;
  location_code: number;
  thumbnail: string;
  low_cost: number;
  low_cost_currency: string;
  offers: HotelOfferModel[] = [new HotelOfferModel()];
  details_data: DetailsDataModel = new DetailsDataModel();
}

export class OfferModel {
  hotel_search_code: string;
  room_type: string;
  room_basis: string;
  room_type_array: string[];
  room_basis_description: string;
  availability: number;
  total_price: number;
  base_price: number;
  nigth_price: number;
  currency: string;
  cxl_deadline: string;
  preferred: number;
  remark: string;
  special_offer: string;
  meal_plan: string = '';
  market_id: string = '';
  contract_id: number = 0;
  rate_key: string = '';
  interface_info: string = '';
  price_break_down: PriceBreakDownModel;
}


export class HotelDetailModel {
  id: number;
  hotel_code: string;
  hotel_name: string;
  stars: number;
  thumbnail: string;
  address: string;
  city_code: string;
  phone: string;
  fax: string;
  description: string;
  descriptionArray: string[];
  hotel_facilities: any;
  room_facilities: string;
  room_count: number;
  pictures: PicturesModel[];
  trip_advisor: string;
  latitude: string;
  longitude: string;
}

export class PriceBreakDownModel {
  hotel_name: string;
  rooms: RoomPriceBreakDownModel[];
}

export class OffersBreakDownModel {
  currency: string;
  from_date: string;
  to_date: string;
  price: number;
}

export class RoomPriceBreakDownModel {
  children: string;
  cots: string;
  room_type: string;
  price_breakdowns: OffersBreakDownModel[];
  base_cost: number;
  total_cost: number;
  check_in: string;
  check_out: string;
  nights: 3;
  currency: string;
}

export class PicturesModel {
  id: number = 0;
  name: string = '';
  url: string = '';
  hotel_id: number;
  created_at: string;
  updated_at: string;
}

export class HotelCityModel {
  id: number;
  name: string;
}

export class HotelCountryModel {
  id: number;
  name: string;
}

export class DetailsDataModel {
  address: string;
  city: HotelCityModel = new HotelCityModel();
  city_code: number;
  country: HotelCountryModel = new HotelCountryModel();
  description: string;
  descriptionArray: string[] = [''];
  fax: string;
  hotel_code: number;
  hotel_facilities: string[] = [''];
  hotel_name: string;
  id: number;
  latitude: number;
  longitude: number;
  phone: number;
  pictures: PicturesModel[] = [new PicturesModel()];
  room_count: number;
  room_facilities: string;
  stars: number;
  thumbnail: string;
  trip_advisor: string;
}


export class HotelOfferModel {
  room_basis: string;
  room_type: string;
  room_type_array: string[] = [''];
  hotel_search_code: string;
  room_basis_description: string;
  availability: number;
  base_price: number;
  total_price: number;
  currency: string;
  cxl_deadline: string;
  preferred: number;
  remark: string;
  special_offer: string;
  non_ref: boolean;
}

