import {GenericModel, HotelModel, OfferModel, SearchModel, UserModel} from '.';

export class ReservationModel {
  search: SearchModel;
  hotel: HotelModel;
  offer: OfferModel;
  book_info: UserModel;
  use_qroo_info: number;
  guests: GuestModel[];
  list_hotels: HotelModel[];
  actual_time: number;
  destinations: GenericModel[];
  automatic_refresh: boolean;
  quoteReservation: boolean;
  booking_valuation: boolean = false;
}

export class GuestModel {
  room_type: string;
  room_number: number;
  adults: { title: string, name: string, last_name: string }[];
  childrens: { title: string, name: string, last_name: string, age: number }[];
}


