import {Component, OnInit} from '@angular/core';

declare function closeWindow(param1: any, param2: any);

@Component({
  selector: 'app-w-under-construction',
  templateUrl: './w-under-construction.component.html',
  styleUrls: ['./w-under-construction.component.css']
})
export class WUnderConstructionComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  closeW(event, wId) {
    //
    closeWindow(event, wId);
  }

}
