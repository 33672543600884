import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'CustomAmount'
})
export class CustomAmountPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any): string {
    if (value == null) {
      return '0';
    }
    var valueTransformed = this.currencyPipe.transform(value);
    var valueWithoutSig = valueTransformed.substr(1, valueTransformed.length);
    var partes = valueWithoutSig.split('.');
    if (partes[partes.length - 1] == '00') {
      return valueWithoutSig.substr(0, valueWithoutSig.length - 3);
    }
    return valueWithoutSig;
  }
}
